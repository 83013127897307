@import '../../../../../Style.scss';

.leaderboard_modal {
    .MuiBox-root {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: fit-content;
        width: 93%;
        max-width: 524px;
        border: none !important;
        outline: none !important;
        border-radius: 0px;
        padding-inline: 20px;
        // padding-block: 20px 0;
    }

    .MuiBackdrop-root {
        background-color: var(--backDrop_bg);
    }

    .current_participent_rank {
        width: 100%;
        text-align: center;
        margin-block-start: 65px;

        p {
            font-family: var(--poppin_stack);
            font-weight: 500;
            font-size: 20px;
            color: var(--button_white_text);
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-family: var(--poppin_stack);
                font-weight: 500;
                font-size: 20px;
                line-height: 35px;
                color: var(--gold_E4AA31);
                display: flex;
                align-items: center;
                justify-content: center;
                padding-inline: 5px;

                strong {
                    font-size: 36px;
                }
            }
        }
    }

    .leader_board_rank_barchart {
        width: 100%;
        height: fit-content;

        .leader_board_rank_chart {
            display: table;
            table-layout: fixed;
            padding-top: 16px;
            margin: 0 auto;
            height: 100%;
            background-image: linear-gradient(bottom, rgba(0, 0, 0, 0.1) 2%, transparent 2%);
            background-size: 100% 50px;
            background-position: left top;

            li {
                position: relative;
                display: table-cell;
                vertical-align: bottom;
                width: 140px;

                .level_display {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    margin-block-start: 12px;
                    padding-inline: 8px 9px;
                    padding-block: 4px;
                    background: var(--background_white_85);
                    border-radius: 100px;

                    img {
                        width: 24px;
                    }

                    p {
                        font-size: 18px;
                        line-height: 18px;
                        color: var(--activation_text);
                        font-weight: 500;
                        font-family: var(--poppin_stack);
                        position: relative;
                        z-index: 1;
                    }
                }

                &:first-child {
                    &::after {
                        width: 100%;
                        height: 44px;
                        position: absolute;
                        bottom: -2px;
                        left: 0px;
                        content: '';
                        background: url(../../../../../../public/assets/leaderboard/cup2.svg) no-repeat center;
                    }

                    & > div {
                        background: var(--leaderboard_bg_1);
                        box-shadow: 0px 0.635542px 1.27108px rgba(0, 0, 0, 0.05),
                            0px 0.635542px 3.81325px 1.27108px rgba(0, 0, 0, 0.05),
                            inset -12px 0px 0px rgba(0, 0, 0, 0.25);
                        border-radius: 20px 20px 12px 12px;
                        height: 132px;

                        &::after {
                            width: 100%;
                            height: 65%;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            font-size: 40px;
                            line-height: 40px;
                            content: '2';
                            font-weight: 600;
                            font-family: var(--poppin_stack);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: var(--text_grey);
                            z-index: 2;
                        }
                    }
                }

                &:nth-of-type(2) {
                    &::after {
                        width: 100%;
                        height: 64px;
                        position: absolute;
                        bottom: 0px;
                        left: 0px;
                        content: '';
                        background: url(../../../../../../public/assets/leaderboard/cup1.svg) no-repeat center;
                    }

                    & > div {
                        background: var(--leaderboard_bg_2);
                        box-shadow: 0px 0.635542px 1.27108px rgba(0, 0, 0, 0.05),
                            0px 0.635542px 3.81325px 1.27108px rgba(0, 0, 0, 0.05);
                        border-radius: 20px 20px 12px 12px;
                        height: 168px;

                        &::after {
                            width: 100%;
                            height: 65%;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            font-size: 48px;
                            line-height: 48px;
                            content: '1';
                            font-weight: 600;
                            font-family: var(--poppin_stack);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: var(--leaderBoard_f59300);
                            z-index: 2;
                        }
                    }
                }

                &:nth-of-type(3) {
                    &::after {
                        width: 100%;
                        height: 42px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        content: '';
                        background: url(../../../../../../public/assets/leaderboard/cup3.svg) no-repeat center;
                    }

                    & > div {
                        background: var(--leaderboard_bg_3);
                        box-shadow: 0px 0.635542px 1.27108px rgba(0, 0, 0, 0.05),
                            0px 0.635542px 3.81325px 1.27108px rgba(0, 0, 0, 0.05), inset 12px 0px 0px #ff9f6a;
                        border-radius: 20px 20px 12px 12px;
                        height: 116px;

                        &::after {
                            width: 100%;
                            height: 65%;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            font-size: 40px;
                            line-height: 40px;
                            content: '3';
                            font-weight: 600;
                            font-family: var(--poppin_stack);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: var(--leaderBoard_text2);
                            overflow: hidden;
                            z-index: 2;
                        }
                    }
                }

                &.no_place {
                    .bar {
                        .level_display {
                            display: none;
                        }
                    }

                    .chart_tooltip {
                        display: none;

                        .crown_img {
                            display: none;
                        }

                        .point_star_box {
                            display: none;
                        }

                        & > p {
                            display: none;
                        }
                    }
                }
            }

            .bar {
                margin: 0 0%;
                display: block;
                position: relative;
                border-radius: 12px 12px 0 0;
                display: flex;
                align-items: flex-start;
                justify-content: center;

                i {
                    display: none;

                    svg {
                        display: flex;
                        font-size: 2rem;
                    }
                }
            }

            .chart_tooltip {
                position: relative;
                width: 100%;
                color: var(--button_white_text);
                font-weight: 600;
                font-size: 18px;
                line-height: 14px;
                border-radius: 5px;
                font-size: 20px;
                font-weight: 500;
                font-family: var(--poppin_stack);
                text-align: center;
                padding-block-end: 20px;
                display: block;

                p {
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: var(--button_white_text);
                    font-weight: 500;
                    font-family: var(--poppin_stack);
                    padding-inline: 5px;
                    word-break: break-word;
                }

                .crown_avater {
                    position: relative;

                    &.double {
                        .MuiAvatar-root {
                            position: relative;
                            left: 12%;
                            z-index: 2;

                            &:nth-of-type(2) {
                                left: inherit;
                                right: 12%;
                                z-index: 1;
                            }
                        }
                    }

                    .crown_img {
                        position: absolute;
                        top: -34px;
                        right: 22px;
                    }

                    .images {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .left_image {
                        position: absolute;
                        top: 42%;
                        transform: translateY(-50%);
                        left: 16%;
                        z-index: 0;
                        width: 40px;
                        height: 40px;
                        font-size: 15px;
                        margin-block-end: 0;
                        opacity: 1;
                        border: none;
                    }

                    .right_image {
                        position: absolute;
                        top: 42%;
                        transform: translateY(-50%);
                        right: 16%;
                        z-index: 0;
                        width: 40px;
                        height: 40px;
                        font-size: 15px;
                        margin-block-end: 0;
                        opacity: 1;
                        border: none;
                    }
                }

                .MuiAvatar-root {
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: var(--poppin_stack);
                    border: 4px solid var(--button_white_text);
                    font-size: 24px;
                    font-weight: 500;
                    margin-block-end: 12px;
                    margin-inline: auto;
                    position: relative;
                    z-index: 3;

                    &.noAvatar {
                        border: none;
                    }
                }

                .point_star_box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    margin-block-start: 16px;

                    img {
                        width: 20px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 16px;
                        color: var(--textWhite);
                        font-weight: 500;
                        font-family: var(--poppin_stack);
                        position: relative;
                        z-index: 1;
                    }
                }
            }
        }
    }
}

@media (max-width: 520px) {
    .leaderboard_modal .MuiBox-root {
        padding: 0 !important;
        width: 92%;
    }

    .leaderboard_modal .barchart_sec .inner_barchart .chart div .chart_tooltip .MuiAvatar-root {
        width: 70px;
        height: 70px;
    }
}
