@import '../../../../../Style.scss';

.video_Upload_card {
    background: var(--background_white);
    border-radius: 0 0 12px 12px;
    padding-block: 20px 0;
    padding-inline: 32px 16px;
    border: 1px solid var(--border_gray);

    // margin-top: 100px;
    .imageUpload_wraper {
        width: 100%;
        padding-block: 6px 32px;
        padding-inline: 0px 16px;
        height: 400px;
        overflow: auto;
    }

    .inner_image {
        width: 100%;
        align-content: flex-start;
    }

    .content_box {
        // padding: 20px 20px;
        align-items: center;
        justify-content: center;

        // height: 344px;
        .react-player.video_upload {
            width: 100% !important;
            height: 100% !important;
            display: flex;

            video {
                border-radius: 12px;
            }
        }

        .video_player {
            height: 275px;
        }

        .video_button {
            width: auto;
            position: absolute;
            z-index: 11;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            text-align: start;
            padding-block: 0;
            padding-inline: 10px;
            text-transform: none;

            button {
                border-radius: 50%;
                background: rgba(73, 69, 79, 0.2);
                border: 1px solid rgba(255, 255, 255, 0.3);
                padding: 12px;
                cursor: pointer;
                text-transform: none;

                svg {
                    color: var(--textWhite);
                    font-size: 20px;
                }
            }
        }

        p {
            font-family: var(--mali);
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            color: var(--textColor);
        }
    }

    .inner_imagesupload {
        width: 100%;

        .video_boxs {
            position: relative;
            border-radius: 12px;
            box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
            cursor: pointer;
            background: var(--background_black);

            &:hover {
                -webkit-transition: 0.5s ease-in-out;
                -moz-transition: 0.5s ease-in-out;
                -o-transition: 0.5s ease-in-out;
                transition: 0.5s ease-in-out;
                box-shadow: 0px 28px 20px -12px rgba(0, 0, 0, 0.16), 0px 4px 20px 2px rgba(0, 0, 0, 0.02);
            }

            img {
                border-radius: 12px !important;
            }

            .sh_footer {
                width: 100%;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 0px 0px 12px 12px;
                position: relative;
                // bottom: 0;
                z-index: 2;
                background: var(--background_black);

                // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10.94%, rgba(0, 0, 0, 0.3) 64.58%);
                .profile {
                    width: auto;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .MuiAvatar-root {
                        width: 32px;
                        height: 32px;
                        font-size: 18px;
                    }
                }

                .like_count {
                    width: auto;
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;
                    // box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05),
                    //     0px 2px 1px rgba(0, 0, 0, 0.05);
                    width: auto;

                    svg {
                        color: var(--textWhite);
                        font-size: 24px;
                        display: flex;

                        &.red {
                            color: var(--heart_color);
                        }

                        &.stroke {
                            stroke: var(--textWhite);
                            stroke-width: 3px;
                        }

                        &:hover {
                            color: var(--heart_color);
                        }
                    }

                    span {
                        color: var(--textWhite);
                        font-family: var(--poppin_stack);
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 14px;
                    }
                }

                p {
                    text-align: start;
                    font-family: var(--poppin_stack);
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                    color: var(--textWhite);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 140px;
                    white-space: nowrap;
                }
            }
        }
    }
}
