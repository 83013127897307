@use '../../../../../../../Style.scss' as *;

.singleQuestion {
    width: 100%;
    padding: 16px 0 16px 20px;
    border-bottom: 1px solid var(--caption_border);

    .single_top {
        width: 100%;
        @include displayFlex(row, center, space-between);
        gap: 12px;

        .question_profile {
            width: 100%;
            @include displayFlex(row, center, flex-start);
            gap: 12px;

            :global(.MuiAvatar-root) {
                height: 32px;
                width: 32px;
                font-size: 18px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .name {
                font-size: 16px;
                font-weight: var(--fw_medium);
                color: var(--para_color);
                font-family: var(--poppin_stack);
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .upgrade_ans {
            flex-shrink: 0;
            @include displayFlex(row, center, flex-start);

            width: 48px;
            height: fit-content;
            gap: 6px;
            cursor: pointer;

            .icon {
                display: flex;
                margin-block-end: 3px;
            }

            .number {
                font-size: 16px;
                color: var(--para_color);
                font-weight: var(--fw_medium);
            }
        }

        .answered {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;

            font-size: 12px;
            font-weight: var(--fw_medium);
            color: var(--answeredText);

            padding-block: 4px;
            padding-inline: 12px;
            border-radius: 100px;
            background: var(--answeredLightGreen);

            .icon {
                @include displayFlex(row, center, center);

                svg {
                    path {
                        fill: var(--answeredGreen);
                    }
                }
            }
        }

        .deleteBtn {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2px;

            font-family: var(--poppin_stack);
            font-size: 12px;
            font-weight: 500;
            color: #b3261e;
            text-transform: none;

            width: 32px;
            min-width: 0;
            height: 32px;
            // padding-block: 4px;
            // padding-inline: 15px;
            border-radius: 100px;
            background: var(--deleteQna);

            .icon {
                font-size: 20px;
            }

            :global(.MuiLoadingButton-loadingIndicator) {
                color: var(--deleteQnaHover);
            }

            &:hover {
                color: var(--deleteQnaText);
                background: var(--deleteQnaHover);
            }
        }
    }

    .question {
        width: 100%;
        margin-block-start: 12px;

        .text {
            font-size: 16px;
            color: var(--para_color);
        }
    }
}
