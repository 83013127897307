@import '../../../../../Style.scss';

.imageUpload_card {
    background: var(--background_white);
    border-radius: 0 0 12px 12px;
    padding-block: 20px 0;
    padding-inline: 32px 16px;
    border: 1px solid #ecf0f4;
    // margin-top: 100px;
    .inner_image {
        width: 100%;
        align-content: flex-start;
    }
    .inner_imagesupload {
        width: 100%;
        height: 400px;
        overflow: auto;
        padding-block: 0px 32px;
        padding-inline: 0px 16px;
        .images_boxs {
            position: relative;
            border-radius: 12px;
            box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
            cursor: pointer;

            &:hover {
                -webkit-transition: 0.5s ease-in-out;
                -moz-transition: 0.5s ease-in-out;
                -o-transition: 0.5s ease-in-out;
                transition: 0.5s ease-in-out;
                box-shadow: 0px 28px 20px -12px rgba(0, 0, 0, 0.16), 0px 4px 20px 2px rgba(0, 0, 0, 0.02);
            }

            img {
                border-radius: 12px !important;
                height: 100%;
                object-fit: contain;
            }
            .big_sh_body {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: calc(100% - 45px);
                z-index: 3;
            }

            .sh_footer {
                width: 100%;
                padding-block: 10px;
                padding-inline: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // background: rgba(255, 255, 255, 0.3);
                border-radius: 0px 0px 12px 12px;
                position: absolute;
                bottom: 0;
                z-index: 2;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10.94%, rgba(0, 0, 0, 0.3) 64.58%);
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
                    /* CP2/Elevation Light/3 */
                    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05);
                    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.05));
                    border-radius: 12px;
                    z-index: -1;
                }
                .profile {
                    width: auto;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .MuiAvatar-root {
                        width: 32px;
                        height: 32px;
                        font-size: 18px;
                    }
                }
                .like_count {
                    width: auto;
                    // padding: 4px 9px;
                    // border: 2px solid #fff;
                    // background: #ef476f;
                    // border-radius: 40px;
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;
                    // box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05),
                    //     0px 2px 1px rgba(0, 0, 0, 0.05);
                    width: auto;
                    svg {
                        color: #fff;
                        font-size: 24px;
                        display: flex;
                        &.red {
                            color: var(--heart_color);
                        }
                        &.stroke {
                            stroke: #fff;
                            stroke-width: 3px;
                        }
                        &:hover {
                            color: var(--heart_color);
                        }
                    }
                    span {
                        color: #fff;
                        font-family: var(--poppin_stack);
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
                p {
                    text-align: start;
                    font-family: var(--poppin_stack);
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                    color: var(--textWhite);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 140px;
                    white-space: nowrap;
                }
            }
        }
    }
}

// .images_upload_modal {
//     .MuiBox-root {
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         width: 100vw;
//         height: 100vh;
//         display: flex;
//         min-width: 700px;
//         border: none !important;
//         outline: none !important;
//         // background: rgba(255, 255, 255, 0.274);
//         padding: 30px;
//         .close_modal {
//             position: absolute;
//             top: 20px;
//             right: 20px;
//             z-index: 111;
//             cursor: pointer;
//             width: 30px;
//             height: 30px;
//             border-radius: 50%;
//             background: #fff;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             opacity: 0.8;
//             &:hover {
//                 opacity: 1;
//             }
//         }
//         .big_sh_box {
//             /* CP2/Elevation Light/3 */
//             box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05),
//                 0px 2px 1px rgba(0, 0, 0, 0.05);
//             position: relative;
//             border-radius: 12px;
//             width: 450px;
//             height: 450px;
//             margin: 0 auto;
//             position: relative;

//             .big_sh_content {
//                 // padding: 10px;
//                 text-align: center;
//                 // padding-top: 20px;
//                 // padding-bottom: 60px;
//                 img {
//                     width: 450px;
//                     height: 450px;
//                     object-fit: cover;
//                     border-radius: 12px !important;
//                 }
//                 .quote {
//                     text-align: center;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     margin-bottom: 20px;
//                     svg {
//                         font-size: 50px;
//                         display: flex;
//                         transform: rotate(180deg);
//                     }
//                 }
//                 p {
//                     font-family: var(--mali) !important;
//                     font-style: normal;
//                     font-weight: normal;
//                     font-size: 24px;
//                     line-height: 30px;
//                     color: #1c1b1f;
//                     text-align: center;
//                     padding: 0 40px;
//                 }
//             }

//             .images_content {
//                 width: 100%;
//                 padding: 10px;
//                 position: absolute;
//                 bottom: 60px;
//                 z-index: 1;
//                 p {
//                     text-align: left;
//                     font-family: var(--poppin_stack);
//                     font-style: normal;
//                     font-weight: 600;
//                     font-size: 16px;
//                     line-height: 20px;
//                     color: #fff;
//                     text-overflow: ellipsis;
//                     overflow: hidden;
//                     width: 150px;
//                     white-space: nowrap;
//                 }
//             }
//             .sh_footer {
//                 width: 100%;
//                 padding: 10px;
//                 display: flex;
//                 align-items: center;
//                 justify-content: space-between;
//                 // background: rgba(255, 255, 255, 0.3);
//                 border-radius: 0px 0px 12px 12px;
//                 position: absolute;
//                 bottom: 0;
//                 z-index: 2;
//                 &:after {
//                     content: '';
//                     position: absolute;
//                     top: 0;
//                     left: 0;
//                     width: 100%;
//                     height: 100%;
//                     border-radius: 12px;
//                     background: linear-gradient(
//                         180deg,
//                         rgba(0, 0, 0, 0) 0%,
//                         rgba(0, 0, 0, 0.6) 100%
//                     );
//                     z-index: -1;
//                 }
//                 .name_box {
//                     display: flex;
//                     align-items: center;
//                     gap: 0.5rem;
//                 }
//                 .like_count {
//                     width: auto;
//                     // padding: 4px 9px;
//                     // border: 2px solid #fff;
//                     // background: #ef476f;
//                     // border-radius: 40px;
//                     display: flex;
//                     align-items: center;
//                     gap: 0.3rem;
//                     // box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05),
//                     //     0px 2px 1px rgba(0, 0, 0, 0.05);
//                     width: auto;
//                     svg {
//                         color: #fff;
//                         font-size: 24px;
//                         display: flex;
//                         cursor: pointer;
//                         &.red {
//                             color: var(--heart_color);
//                         }
//                         &:hover {
//                             color: var(--heart_color);
//                         }
//                     }
//                     span {
//                         color: #fff;
//                         font-family: var(--poppin_stack);
//                         font-style: normal;
//                         font-weight: 500;
//                         font-size: 14px;
//                         line-height: 14px;
//                     }
//                 }
//                 p {
//                     text-align: left;
//                     font-family: var(--poppin_stack);
//                     font-style: normal;
//                     font-weight: 600;
//                     font-size: 16px;
//                     line-height: 20px;
//                     color: #fff;
//                     text-overflow: ellipsis;
//                     overflow: hidden;
//                     width: 150px;
//                     white-space: nowrap;
//                 }
//             }
//         }

//         /*Sky Slider Styles */
//         .sky-slider {
//             width: 100%;
//             position: relative;
//             transition: 300ms ease-in-out;
//             // height: calc(100vh - 60px);

//             .sky-slider__img-container {
//                 list-style: none;
//                 display: block;
//                 height: 100%;

//                 li {
//                     display: none;
//                     height: 100%;

//                     &.active {
//                         display: flex;
//                         align-items: center;
//                     }

//                     img {
//                         margin: 0 auto;
//                         display: block;
//                     }
//                 }
//             }

//             //end of sky-slider__img-container

//             //Controls
//             .sky-slider-controls {
//                 top: 50%;
//                 // transform: translateY(-50%);
//                 position: absolute;
//                 width: 100%;
//                 min-height: 6rem;

//                 ul {
//                     display: block;
//                     position: relative;
//                     list-style: none;

//                     li {
//                         position: absolute;
//                         // top: calc(39vh - 48px);
//                     }

//                     .sky-slider__prev {
//                         left: -1px;
//                         background: rgba(250, 251, 255, 0.5);
//                         border-radius: 50%;

//                         button {
//                           display: inline-block;
//                           border: none;
//                           background: none;
//                           cursor: pointer;
//                           background-size: 2.4rem 2.4rem;
//                           padding: 1rem;
//                           height: 3rem;
//                           width: 3rem;
//                           border-radius: 50%;
//                           color: var(--activation_text);

//                           .chevron-right {
//                             height: 100%;
//                             fill: var(--activation_text);
//                             transition: fill 0.3s ease-in;
//                             transform: rotateY(180deg);
//                           }

//                           &:hover {
//                             .chevron-right {
//                               fill: var(--activation_text);
//                               transition: fill 0.3s ease-in-out;
//                             }
//                           }

//                           //end of hover
//                         }

//                         //end of button
//                       }

//                       //end of sky-slider__prev

//                       .sky-slider__next {
//                         right: -1px;
//                         background: rgba(250, 251, 255, 0.5);
//                         border-radius: 50%;

//                         button {
//                           display: inline-block;
//                           border: none;
//                           background: none;
//                           cursor: pointer;
//                           background-size: 2.4rem 2.4rem;
//                           padding: 1rem;
//                           height: 3rem;
//                           width: 3rem;
//                           border-radius: 50%;
//                           color: var(--activation_text);

//                           .chevron-right {
//                             height: 100%;
//                             fill: var(--activation_text);
//                             transition: fill 0.2s ease-in-out;
//                           }

//                           &:hover,
//                           &:focus {
//                             outline: none;

//                             .chevron-right {
//                               fill: var(--activation_text);
//                               transition: fill 0.2s ease-in;
//                             }
//                           }

//                           //end of hover
//                         }

//                         //end of button
//                       }
//                 }
//             }

//             //end of sky-slider__controls
//         }

//         //end of sky-slider

//         @media screen and (min-width: 320px) {
//             main {
//                 width: 100%;
//             }
//         }
//     }
// }

// @media (max-width: 700px) {
//     .images_upload_modal .MuiBox-root .sky-slider .sky-slider-controls ul .sky-slider__next button,
//     .images_upload_modal .MuiBox-root .sky-slider .sky-slider-controls ul .sky-slider__prev button{
//         height: 2.5rem;
//         padding: 13px;
//         width: 2.5rem;
//     }
//     .images_upload_modal .MuiBox-root .big_sh_box{
//         width: 400px;
//         height: auto;
//     }
//     .images_upload_modal .MuiBox-root{
//         width: 100%;
//         min-width: 100%;
//         height: 100vh;
//     display: flex;
//     padding: 0 15px !important;
//     }
//     .images_upload_modal .MuiBox-root .sky-slider .sky-slider-controls{
//         min-height: auto;
//     }
//     .images_upload_modal .MuiBox-root .sky-slider .sky-slider-controls{
//         top: 47%;
//     }
//     .images_upload_modal .MuiBox-root .big_sh_box .big_sh_content img{
//         width: 100%;
//         height: 350px;
//         object-fit: contain;
//     }
// }
// @media (max-width: 576px) {
//     .images_upload_modal .MuiBox-root .sky-slider .sky-slider-controls ul .sky-slider__next button,
//     .images_upload_modal .MuiBox-root .sky-slider .sky-slider-controls ul .sky-slider__prev button{
//         height: 2.5rem;
//         padding: 13px;
//         width: 2.5rem;
//     }
//     .images_upload_modal .MuiBox-root .big_sh_box{
//         width: 300px;
//     }
//     .images_upload_modal .MuiBox-root .big_sh_box .big_sh_content img{
//         height: 300px;
//     }
// }
// @media (max-width: 450px) {
//     .images_upload_modal .MuiBox-root .sky-slider .sky-slider-controls ul .sky-slider__next button,
//     .images_upload_modal .MuiBox-root .sky-slider .sky-slider-controls ul .sky-slider__prev button{
//         height: 25px;
//         padding: 7px;
//         width: 25px;
//     }
//     .images_upload_modal .MuiBox-root .big_sh_box{
//         width: 240px;
//     }
//     .images_upload_modal .MuiBox-root{
//         padding: 0 10px !important;
//     }
// }
