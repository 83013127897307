@use '../../../../../Style.scss' as *;

.qna {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: max-content;
    @include displayFlex(row, center, center);
    gap: 12px;
    padding: 8px 17px;
    background: var(--para_color);
    border-radius: 40px;
    cursor: pointer;

    @include max(767px) {
        right: 10px;
    }

    .icon {
        display: flex;
    }

    .text {
        color: var(--textWhite);
        font-weight: var(--fw_medium);
        font-family: var(--poppin_stack);

        // @include max(767px) {
        //     display: none;
        // }
    }
}

html {
    &:global(.rtl) {
        .qna {
            right: initial;
            left: 20px;
        }
    }
}
