@use '../../../../../../../Style.scss' as *;

.card_head {
    width: 100%;
    padding-block-start: 20px;
    padding-inline: 32px;

    @include max(575px) {
        padding-inline: 15px;
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        width: 100%;
        height: 100%;
        padding-block-end: 20px;
        border-bottom: 2px solid var(--border_gray);

        .card_head_left {
            width: auto;

            .heading_text {
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 500;
                font-family: var(--poppin_stack);
                font-size: 18px;
                color: var(--textblack);

                .icon {
                    display: flex;

                    svg {
                        font-size: 24px;
                    }
                }
            }
        }

        .close_modal {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--textColor);
            padding: 0;
            width: 30px;
            height: 30px;
            opacity: 0.8;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }

            svg {
                width: 100%;
                height: 100%;
                padding: 3px;
            }
        }
    }
}
