@import '../../../../../../Style.scss';

.quickpoll_modal {
    .quickpoll_modal_box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 93%;
        // height: 100vh;
        max-width: 720px;
        border: none !important;
        outline: none !important;
        background: var(--background_white);
        border-radius: 24px;
        padding-block: 26px;
        padding-inline: 32px;

        .close_modal {
            position: absolute;
            top: 22px;
            right: 36px;
            z-index: 111;
            cursor: pointer;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: var(--background_white);
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.8;
            color: var(--textColor);

            &:hover {
                opacity: 1;
            }
        }

        .quickpoll_head {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 14px;
            padding-block-end: 20px;

            img {
                filter: var(--filter_invert);
            }

            h4 {
                font-family: var(--poppin_stack);
                color: var(--textColor);
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
            }
        }

        .MuiDivider-root {
            margin-block: 18px 24px;
            border-color: var(--border_gray);
            border-width: 1px;
        }

        .instraction_head {
            width: 100%;
            padding-block: 28px;
            padding-inline: 0px;

            .type_ans_box {
                width: 100%;
                display: flex;
                align-items: flex-start;
                gap: 1rem;

                img {
                    width: 48px;
                    height: 48px;
                    border-radius: 40px;
                }

                h5 {
                    text-align: start;
                    font-family: var(--poppin_stack);
                    color: var(--para_color);
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 22px;
                }

                .chat_text {
                    flex: 1;

                    h4 {
                        text-align: start;
                        font-family: var(--poppin_stack);
                        color: var(--para_color);
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
            }
        }

        .quickpoll_select {
            width: 100%;

            &.quickpoll_5 {
                .quickpoll_wraper {
                    .quickpoll_box {
                        width: 122px;
                    }
                }
            }

            &.quickpoll_7 {
                .quickpoll_wraper {
                    gap: 10px;

                    // .quickpoll_box {
                    // width: 122px;
                    // }
                }
            }

            &.quickpoll_8 {
                .quickpoll_wraper {
                    gap: 10px;

                    // .quickpoll_box {
                    // width: 122px;
                    // }
                }
            }

            &.quickpoll_bar {
                width: 100%;

                .quickpoll_wraper {
                    .quickpoll_box {
                        p {
                            font-family: var(--poppin_stack);
                            color: var(--activation_text);
                            font-size: 40px;
                            line-height: 40px;
                            font-weight: 700;

                            br {
                                display: none;
                            }
                        }

                        &:is(.active, :active) {
                            background: none;
                        }

                        &.active {
                            p {
                                color: var(--textWhite) !important;
                            }

                            background: var(--checkboxGreen);
                        }

                        &.disable {
                            cursor: not-allowed;
                        }

                        &:first-child {
                            &:hover {
                                background: var(--checkboxGreen);

                                p {
                                    color: var(--textWhite) !important;
                                }
                            }

                            &.active {
                                p {
                                    color: var(--textWhite) !important;
                                }

                                background: var(--checkboxGreen);
                            }
                        }

                        &:nth-of-type(2) {
                            &:hover {
                                background: var(--checkboxPink);

                                p {
                                    color: var(--textWhite) !important;
                                }
                            }

                            &.active {
                                p {
                                    color: var(--textWhite) !important;
                                }

                                background: var(--checkboxPink);
                            }
                        }

                        &:nth-of-type(3) {
                            &:hover {
                                background: var(--checkboxBlue);

                                p {
                                    color: var(--textWhite) !important;
                                }
                            }

                            &.active {
                                p {
                                    color: var(--textWhite) !important;
                                }

                                background: var(--checkboxBlue);
                            }
                        }

                        &:nth-of-type(4) {
                            &:hover {
                                background: var(--colorYelow);

                                p {
                                    color: var(--textWhite) !important;
                                }
                            }

                            &.active {
                                p {
                                    color: var(--textWhite) !important;
                                }

                                background: var(--colorYelow);
                            }
                        }

                        &:nth-of-type(5) {
                            &:hover {
                                background: var(--color_deepPurple);

                                p {
                                    color: var(--textWhite) !important;
                                }
                            }

                            &.active {
                                p {
                                    color: var(--textWhite) !important;
                                }

                                background: var(--color_deepPurple);
                            }
                        }

                        &:nth-of-type(6) {
                            &:hover {
                                background: var(--color_orange);

                                p {
                                    color: var(--textWhite) !important;
                                }
                            }

                            &.active {
                                p {
                                    color: var(--textWhite) !important;
                                }

                                background: var(--color_orange);
                            }
                        }

                        &:nth-of-type(7) {
                            &:hover {
                                background: var(--deepgreen);

                                p {
                                    color: var(--textWhite) !important;
                                }
                            }

                            &.active {
                                p {
                                    color: var(--textWhite) !important;
                                }

                                background: var(--deepgreen);
                            }
                        }

                        &:nth-of-type(8) {
                            &:hover {
                                background: var(--deeporange);

                                p {
                                    color: var(--textWhite) !important;
                                }
                            }

                            &.active {
                                p {
                                    color: var(--textWhite) !important;
                                }

                                background: var(--deeporange);
                            }
                        }
                    }
                }
            }

            .quickpoll_wraper {
                display: flex;
                align-items: center;
                gap: 16px;
                padding-block: 10px;
                padding-inline: 0px;

                &.quickpoll_strong {
                    .quickpoll_box {
                        p {
                            min-height: 42px;
                        }
                    }
                }

                .quickpoll_box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 10px;
                    text-align: center;
                    flex-grow: 1;
                    background: var(--checkboxColor);
                    border-radius: 16px;
                    min-height: 152px;
                    padding: 10px;
                    cursor: pointer;
                    text-transform: none;

                    img {
                        width: 54px;
                    }

                    p {
                        font-family: var(--poppin_stack);
                        color: var(--activation_text);
                        font-size: 14px;
                        font-weight: 500;

                        display: flex;
                        align-items: center;
                        // text-transform: capitalize;
                    }

                    &:hover {
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)),
                            var(--background_white);
                    }

                    &:is(.active, :active) {
                        background: var(--active_bg);
                    }
                }
            }
        }
    }
}

.quickpoll_test {
    width: 100px;
}

@media (max-width: 900px) {
    .quickpoll_modal .quickpoll_modal_box {
        max-width: 480px;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_select .quickpoll_wraper {
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_select .quickpoll_wraper .quickpoll_box {
        min-width: 132px;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_select.quickpoll_7 .quickpoll_wraper .quickpoll_box {
        min-width: 96px;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_select.quickpoll_8 .quickpoll_wraper .quickpoll_box {
        min-width: 96px;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_select.quickpoll_4 .quickpoll_wraper .quickpoll_box {
        min-width: 203px;
    }

    // .quickpoll_modal .quickpoll_modal_box .quickpoll_select.quickpoll_5 .quickpoll_wraper .quickpoll_box {
    //     width: auto;
    // }
    .quickpoll_modal .quickpoll_modal_box .quickpoll_select .quickpoll_wraper .quickpoll_box.flex_grow_0 {
        flex-grow: 0;
    }
}

@media (max-width: 600px) {
    .quickpoll_modal .quickpoll_modal_box {
        padding-block: 26px !important;
        padding-inline: 32px !important;
        width: 91%;
    }
}

@media (max-width: 530px) {
    .quickpoll_modal .quickpoll_modal_box .quickpoll_select .quickpoll_wraper .quickpoll_box {
        flex-direction: row;
        min-height: 72px;
        width: 100%;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_select.quickpoll_5 .quickpoll_wraper .quickpoll_box {
        width: 100%;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_select.quickpoll_bar .quickpoll_wraper .quickpoll_box {
        min-height: 48px;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_select .quickpoll_wraper .quickpoll_box p br {
        display: none;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_select.quickpoll_bar .quickpoll_wraper .quickpoll_box p {
        font-size: 24px;
        line-height: 24px;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_select .quickpoll_wraper .quickpoll_box img {
        width: 36px;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_select .quickpoll_wraper {
        flex-direction: column;
        gap: 8px;
        padding-block-start: 0px;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_head {
        padding-block-end: 10px;
    }

    .quickpoll_modal .quickpoll_modal_box .instraction_head {
        display: none;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_select .quickpoll_strong.quickpoll_wraper .quickpoll_box {
        justify-content: flex-start;
        padding-block: 10px;
        padding-inline: 24px;
    }
}

@media (max-width: 320px) {
    .quickpoll_modal .quickpoll_modal_box {
        padding-block: 24px !important;
        padding-inline: 15px !important;
    }

    .quickpoll_modal .quickpoll_modal_box .quickpoll_select .quickpoll_strong.quickpoll_wraper .quickpoll_box {
        justify-content: flex-start;
        padding-block: 10px;
        padding-inline: 15px;
    }
}
