@import url('https://fonts.googleapis.com/css2?family=Mali:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// font-family: 'Poppins', sans-serif;
//font-family: 'El Messiri', sans-serif;
//font-family: 'IBM Plex Sans Arabic', sans-serif;
:root {
    // ========== Font Weight ==========
    --fw_light: 300;
    --fw_regular: 400;
    --fw_medium: 500;
    --fw_semiBold: 600;
    --fw_bold: 700;
    --fw_extraBold: 800;
    --fw_black: 900;

    --poppin_stack: 'Poppins', sans-serif !important;
    --container-size: 1376px;

    --font-stack: 'Roboto', sans-serif;
    --mali: 'Mali', cursive !important;
    --btn_gradient: linear-gradient(75.08deg, #6378ff 0.4%, #8652f4 90.9%);
    --lg_background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;

    --checkboxBlue: #2aa4e3;
    --checkboxYellow: #ffba00;
    --colorYelow: #ffba00;
    --checkboxPurple: #b36cfd;
    --color_deepPurple: #7d29c0;
    --colorBlue: #23a1e2;
    --colorgray: #919191;
    --color_orange: #ff871e;
    --orange: #ff7a00;
    --checkboxDeepGreen: #57d163;
    --uploadImageColor: #565556;
    --deepgreen: #049f76;
    --litegreen: #bdd900;
    --grey: #cdcbcd;
    --red: #fa1d13;
    --deletecolor: #d11a2a;
    --secondarybtn: #eee;
    --secondarybtn_hover: #f5f5f5;
    --secondary_btn_text: #757575;
    --drawing_heading_color: #424242;
    --toolbarbg: #eee;
    --toolbar_active: #e0e0e0;
    --icon_black: #333333;
    --icon_green: #28a745;
    --icon_red: #dc3545;
    --icon_yellow: #ffc107;
    --icon_blue: #17a2b8;
    --star_orange: #ff9a02;
    --deeporange: #f25240;
    --vottting_heart: #bbbbbb;
    --heart_active: #ff1212;
    --chatred: #d96367;
    --chatred_75: rgba(217, 99, 103, 0.75);
    --cancelbtn: #f9dedc;

    --response_border_color: #eef0ff;
    --light_red_bg: #fdf0f1;

    // voting
    --heart_color: #fa541c;
    --box_bg: #fff4ba;

    --graybtn: #e6e1e5;
    --grayBtnHover: #b4b4b4a6;
    // var(--mali): 'Mali', cursive !important;

    --caption_border: #cfd5ff;
    --Activityerroe_color: #d32f2f;
    --active_bg: #cfd5ff;
    --sucess: #3bba59;
    --point_chip_bg: #1aed48;
    --box_btn_hover: #e9ecff;
    --Ripplebg_color: #d6dbff;
    --transition: 0.2s ease-in-out;

    --lightBoxMax-width: 1120px;
    --lightBoxMin-width: 260px;

    --lightBoxMax-weight: 70vw;
    --lightBoxMax-height: 70vh;
    --lightBoxMax-height85: 85vh;
    --lightBoxMin-height: 180px;
    --errorBoundaryBG: hsla(0, 0%, 0%, 0.85);
    --backDrop_bg: hsla(0, 0%, 0%, 0.75);
    --grey_text_939094: #939094;

    // tooltip bg
    --tooltip_bg_color: #1c1b1f;

    // leaderboard
    --gold_E4AA31: #e4aa31;
    --leaderboard_bg_1: #ebebeb;
    --leaderboard_bg_2: #ffdc69;
    --leaderboard_bg_3: #ffd1b8;
    --text_grey: #8b8b8b;
    --leaderBoard_f59300: #f59300;
    --leaderBoard_text2: #d8601d;

    // special case
    --button_white_text: #ffffff;
    --background_white_no_change: #ffffff;
    --input_background_white: #ffffff;
    --light_dadfff: #dadfff;
    --background_cfd5ff: #cfd5ff;
    --activation_text_49454f: #49454f;
    --background_000: #000000;
    --backBlack_02: hsla(0, 0%, 100%, 0.2);
    --backwhite_02: hsla(0, 0%, 100%, 1);

    // new color scheme
    --textWhite: #fff;
    --background_white: #fff;
    --background_white_85: rgba(255, 255, 255, 0.85);
    --background_black: #000;
    --input_color: #000;
    --textColor: #1c1b1f;
    --borderWhite: #fff;
    --border_gray: #ecf0f4;
    --color_ecf0f4: #ecf0f4;
    --placeholder_color: #aeaaae;
    --border_line_color: #d6dbe0;
    --foot_gray: #79747e;
    --join_foot_gray: #79747e;
    --footer-color: #79747e;
    --parple: #6378ff;
    --backgroundcolor: #6378ff;
    --btnbgcolor: #6378ff;
    --parple_08: rgba(99, 120, 255, 0.08);
    --border_prime: #6378ff;
    --activation_text: #49454f;
    --classroombg: #edf0f7;
    --lightparple: #edf0f6;
    --lightbg: #edf0f6;
    --textblack: #000;
    --para_color: #49454f;
    --quillPlaceholder: #808494;
    --userSubText: #938f99;
    --checkboxColor: #f2f3f7;
    --error_color: #b3261e;
    --guest_not_allowed: #ed6c47;
    --progrss_gray: #e6e6e6;

    --checkboxGreen: #06d6a0;
    --checkboxPink: #ef476f;
    --red_ripple_color: #ff9da0;

    // special
    --filter_invert: invert(0);

    // qna
    --answeredLightGreen: #c6f1d0;
    --answeredGreen: #3bba59;
    --answeredText: #49454f;
    --deleteQnaText: #ffffff;
    --deleteQna: rgba(217, 99, 103, 0.12);
    --deleteQnaHover: #b3261e;
}

@media (prefers-color-scheme: dark) {
    :root {
        // special
        --filter_invert: invert(1);

        // special case
        --button_white_text: #ffffff;
        --background_white_no_change: #ffffff;
        --light_dadfff: #36428c;
        --input_background_white: #1b1b1b;
        --background_cfd5ff: #36428c;
        --activation_text_49454f: #2c2c2c;
        --background_000: #000000;
        --backBlack_02: hsla(0, 0%, 100%, 0.2);
        --backwhite_02: #2c2c2c;

        // short Answer
        --blue_clr: #bad6ff;
        --red_clr: #ffacac;

        // changing color
        --textWhite: #2c2c2c;
        --background_white: #2c2c2c;
        --background_white_85: rgba(44, 44, 44, 0.85);
        --background_black: #e6e1e5;
        --textColor: #e6e1e5;
        --parple: #b7c1ff;
        --backgroundcolor: #b7c1ff;
        --btnbgcolor: #b7c1ff;
        --parple_08: #49454f;
        --borderWhite: #2c2c2c;
        --border_gray: #49454f;
        --color_ecf0f4: #49454f;
        --input_color: #fffbfe;
        --placeholder_color: #938f99;
        --border_line_color: #49454f;
        --foot_gray: #938f99;
        --footer-color: #938f99;
        --join_foot_gray: #938f99;
        --border_prime: #b7c1ff;
        --activation_text: #e6e1e5;
        --classroombg: #363636;
        --lightparple: #363636;
        --lightbg: #363636;
        --textblack: #e6e1e5;
        --para_color: #e6e1e5;
        --quillPlaceholder: #938f99;
        --userSubText: #938f99;
        --checkboxColor: #404040;
        --error_color: #f2b8b5;
        --guest_not_allowed: #ff5e30;
        --progrss_gray: #e6e6e6;

        // color not changes
        --checkboxGreen: #06d6a0;
        --checkboxPink: #ef476f;
        --checkboxBlue: #2aa4e3;
        --checkboxYellow: #ffba00;
        --colorYelow: #ffba00;
        --checkboxPurple: #b36cfd;
        --color_deepPurple: #7d29c0;
        --colorBlue: #23a1e2;
        --colorgray: #919191;
        --color_orange: #ff871e;
        --orange: #ff7a00;

        --btn_gradient: linear-gradient(75.08deg, #6378ff 0.4%, #8652f4 90.9%);
        --lg_background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;

        --backDrop_bg: hsla(0, 0%, 0%, 0.75);

        --checkboxDeepGreen: #57d163;
        --uploadImageColor: #565556;
        --deepgreen: #049f76;
        --litegreen: #bdd900;
        --grey: #cdcbcd;
        --red: #fa1d13;
        --deletecolor: #d11a2a;
        --secondarybtn: #eee;
        --secondarybtn_hover: #f5f5f5;
        --secondary_btn_text: #757575;
        --drawing_heading_color: #424242;
        --toolbarbg: #eee;
        --toolbar_active: #e0e0e0;
        --icon_black: #333333;
        --icon_green: #28a745;
        --icon_red: #dc3545;
        --icon_yellow: #ffc107;
        --icon_blue: #17a2b8;
        --star_orange: #ff9a02;
        --deeporange: #f25240;
        --vottting_heart: #bbbbbb;
        --heart_active: #ff1212;
        --chatred: #d96367;
        --chatred_75: rgba(217, 99, 103, 0.75);
        --cancelbtn: #8c1d18;
        --response_border_color: #eef0ff;
        --light_red_bg: #fdf0f1;

        --heart_color: #fa541c;

        --graybtn: #e6e1e5;
        --grayBtnHover: #b4b4b4a6;

        --caption_border: #cfd5ff;
        --Activityerroe_color: #d32f2f;
        --active_bg: #cfd5ff;
        --sucess: #3bba59;
        --point_chip_bg: #1aed48;
        --box_btn_hover: #383c56;
        --Ripplebg_color: #d6dbff;
        --errorBoundaryBG: hsla(0, 0%, 0%, 0.85);
        --grey_text_939094: #939094;

        // votting
        --box_bg: #fff4ba;

        // leaderboard
        --gold_E4AA31: #e4aa31;
        --red_ripple_color: #ff9da0;
        --leaderboard_bg_1: #ebebeb;
        --text_grey: #8b8b8b;

        --leaderboard_bg_2: #ffdc69;
        --leaderboard_bg_3: #ffd1b8;
        --leaderBoard_f59300: #f59300;
        --leaderBoard_text2: #d8601d;

        --deleteQna: #f7bcbe;
        --deleteQnaHover: #f9dedc;
        --deleteQnaText: #d32f2f;

        // qna
        --answeredText: #3bba59;
    }
}

body {
    margin: 0;
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    //     'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 280px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button:disabled,
button.disabled:hover,
button:disabled,
button:disabled:hover {
    cursor: not-allowed;
}

// universal style
* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-family: var(--poppin_stack);
}

p {
    padding: 0px;
    margin: 0px;
}

a {
    padding: 0px;
    margin: 0px;
    outline: none;
}

ol {
    margin: 0;
}

ul {
    padding: 0px;
    margin: 0px;
}

a:hover {
    outline: none !important;
    text-decoration: none;
}

a:active {
    outline: none !important;
    text-decoration: none;
}

a {
    text-decoration: none !important;
}

label {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

ul li {
    padding: 0px;
    margin: 0px;
}

li {
    list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0px;
    margin: 0px;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aea9b4;
    border-radius: 12px;
    width: 6px;
    height: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
    width: 6px;
    height: 6px;
}

.padding0,
.margin0 {
    padding: 0 !important;
}

.margin0 {
    margin: 0 !important;
}

.classroom {
    background: var(--classroombg);
    background-attachment: fixed;
    background-size: cover;
}

.jodit-status-bar {
    display: none;
}

.upload_buttons .video_process_sms {
    text-align: start;
    // font-family: var(--font-stack);
    font-family: var(--poppin_stack);
    color: var(--textColor);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.66;
    display: block;
}

.error_sec {
    width: 100%;
    text-align: start;
    color: var(--error_color);

    &.marginb_4 {
        margin-block-end: 4px;
    }

    span {
        text-align: start;
        // font-family: var(--font-stack);
        font-family: var(--poppin_stack);
        color: var(--error_color);
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.66;
        display: block;
    }
}

.video_loadingbar {
    margin-block-start: 5px;
    display: flex;
    align-items: center;
    gap: 10px;

    .video_count {
        span {
            color: var(--footer-color);
            font-family: var(--poppin_stack);
            font-size: 12px;
        }
    }

    .MuiLinearProgress-root {
        height: 5px;
        // background: $light-bg;
        flex: 1;

        .MuiLinearProgress-bar {
            background-image: linear-gradient(
                135deg,
                #6378ff 31.82%,
                #99c2ff 31.82%,
                #99c2ff 50%,
                #6378ff 50%,
                #6378ff 81.82%,
                #99c2ff 81.82%,
                #99c2ff 100%
            );
            background-size: 15.56px 15.56px;
        }
    }
}

.MuiModal-root .MuiBox-root {
    min-width: var(--lightBoxMin-width);
}

input {
    font-family: var(--poppin_stack);
}

// Error Boundary & API Error Styles
:is(.errorBoundary, .apiError) {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    .errorContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        border-radius: 24px;
        background: var(--background_white);
        padding: 30px;

        img {
            width: 100px;
            height: 100px;
        }

        p {
            font-family: var(--poppin_stack);
            font-size: 16px;
            font-weight: 400;
            color: var(--activation_text);
            text-align: center;
            line-height: 24px;
        }

        button {
            font-family: var(--poppin_stack);
            font-size: 16px;
            color: var(--background_white);
            letter-spacing: 1.1px;
            text-transform: none;

            width: 188px;
            min-width: auto;
            height: 52px;
            min-height: auto;
            background: var(--btn_gradient);
            border-radius: 60px;
            box-shadow: none;
        }
    }
}

.errorBoundary {
    background: var(--errorBoundaryBG);
}

:is(.errorBoundary .errorContent, .camera_modal .MuiBox-root.apiErrorModal_box) {
    width: 95%;
    min-width: 260px;
    max-width: 450px;
    height: fit-content;
}

.preparing_video {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;

    h4 {
        font-family: var(--poppin_stack);
        font-size: 18px;
        font-weight: 500;
        color: var(--activation_text);
        text-align: center;
        line-height: 24px;
    }

    svg {
        height: 80px !important;
        width: 100px !important;
    }
}

// cropper drag

.cropper-drag-box.cropper-modal {
    opacity: 0.8;
}

.cropper-view-box {
    outline: none;
    border: 1px solid #fff;
}

// .cropper-point.point-se::before,
// .cropper-point,
.cropper-center {
    display: none;
}
