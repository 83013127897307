@import '../../../../../../Style.scss';

.img_Upload_modal {
    .MuiBox-root {
        max-height: 97vh;
        overflow: auto;

        @include max(1024px) {
            max-height: 96vh;
        }
    }

    .MuiDivider-root {
        margin-block: 18px 24px;
        border-color: var(--border_gray);
        border-width: 1px;
    }

    .short_ans_head {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;

        img {
            filter: var(--filter_invert);
        }

        h4 {
            font-family: var(--poppin_stack);
            color: var(--textColor);
            font-size: 16px;
            font-weight: 500;
        }
    }

    .croping_icon {
        width: auto;
        width: auto;
        display: flex;
        align-items: center;
        // gap: 21px;
        justify-content: center;

        // padding-top: 10px;
        button {
            min-width: 48px;
            min-height: 44px;
            isolation: isolate;

            &:hover {
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)), #ffffff;
            }

            // &:active{
            //     background: var(--active_bg);
            // }
            .MuiTouchRipple-root {
                z-index: -1;

                .MuiTouchRipple-child {
                    background-color: var(--active_bg);
                }
            }
        }

        img {
            width: 24.78px;
            height: 21px;
            cursor: pointer;
            position: relative;
            z-index: 1;
        }
    }

    .sh_btn {
        width: 100%;
        margin-block-start: 16px;
        text-align: center;

        &.images_crop {
            button {
                // max-width: 240px;
                // margin: 0 16px;
                text-transform: none;

                &.cancel {
                    background: var(--cancelbtn);
                    color: var(--error_color);
                }
            }
        }

        button {
            @include activitybtn;
        }
    }

    .img_upload_warp {
        width: 100%;
        min-height: auto;
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        justify-content: space-between;

        .images_prev {
            background: var(--textblack);
            text-align: center;
            // margin-bottom: 10px;
            display: flex;
            width: 100%;
            border-radius: 12px;
            overflow: hidden;

            img {
                margin-block: 0;
                margin-inline: auto;
                max-height: 347px;
                min-height: 180px;
                object-fit: contain;
                max-width: 100%;
            }
        }

        .searching_img {
            width: 100%;
            text-align: center;

            & > img {
                width: 200px;
                margin-block: 0;
                margin-inline: auto;
            }
        }

        .power_by {
            width: 100%;
            text-align: center;
        }
    }

    .images_list {
        width: 100%;

        .MuiImageList-standard {
            padding: 5px;
        }

        .MuiImageListItem-standard {
            width: auto;
            height: auto !important;
            border-radius: 12px;
            cursor: pointer;
            border: 4px solid transparent;

            &.active {
                border: 4px solid var(--parple);
                // outline: 4px solid var(--parple);
                outline: none;
            }

            &:hover {
                border: 4px solid var(--parple);
                // outline: 4px solid var(--parple);
                outline: none;
            }

            img {
                width: 100%;
                height: 100% !important;
                aspect-ratio: 155/130;
                border-radius: 8px;
            }
        }
    }
}

.Image_upload {
    // min-height: 570px;
    background: var(--background_white);
    border-radius: 12px;
    padding: 16px;
    text-align: center;

    // margin-top: 24px;
    .short_ans_form {
        width: 100%;
    }

    .short_ans_head {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;

        img {
            filter: var(--filter_invert);
        }

        h4 {
            font-family: var(--poppin_stack);
            color: var(--textColor);
            font-size: 16px;
            font-weight: 500;
        }
    }

    .text_area {
        width: 100%;

        .MuiFormControl-root {
            width: 100%;

            .MuiOutlinedInput-root {
                border-radius: 12px;

                &.Mui-focused .MuiOutlinedInput-notchedOutline {
                    border-color: var(--parple);
                }
            }
        }

        label {
            color: var(--quillPlaceholder);
        }

        label.Mui-focused {
            color: var(--parple);
        }

        .MuiOutlinedInput-notchedOutline {
            border: 1px solid var(--border_gray);
        }
    }

    .sh_btn {
        width: 100%;
        margin-block-start: 16px;
        text-align: center;

        button {
            background: var(--btn_gradient);
            color: var(--textWhite);
            width: 100%;
            height: 48px;
            border-radius: 50px;
            box-shadow: none;
            font-family: var(--poppin_stack);
            font-size: 16px;
            font-weight: 500;
            margin-block: 0;
            margin-inline: auto;
            text-transform: none;
        }
    }

    .MuiDivider-root {
        margin-block: 18px 24px;
        border-color: var(--border_gray);
        border-width: 1px;
    }

    .type_ans_warp {
        width: 100%;
        // min-height: 480px;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: space-between;

        .type_ans_box {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 1rem;

            img {
                width: 60px;
                height: 60px;
                border-radius: 30px;
            }

            h5 {
                text-align: start;
                font-family: var(--poppin_stack);
                color: var(--para_color);
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    .chatbot_sec {
        .chatbox .type_ans_box.colum_rev .chat_text {
            &.update_images {
                padding: 0;
                overflow: hidden;

                //    max-height: 235px;
                img {
                    width: 100%;
                    height: 235px;
                    object-fit: contain;
                    border-radius: 12px 12px 0 0;
                }

                h5 {
                    padding: 12px;
                }
            }
        }
    }

    .Image_upload_buttons {
        width: 100%;

        .image_upload_grid {
            display: flex;
            align-items: center;
            margin-block: 0;
            margin-inline: -5.5px;

            .box_6 {
                width: 50%;
                padding-block: 0px;
                padding-inline: 5.5px;
            }

            .box_12 {
                width: 100%;
                padding-block: 0px;
                padding-inline: 5.5px;
                position: relative;
            }

            .box_image {
                background: var(--checkboxColor);
                border-radius: 16px;
                width: 100%;
                height: 146px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                label {
                    cursor: pointer;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                }

                img {
                    width: 44px;
                }

                p {
                    font-family: var(--poppin_stack);
                    color: var(--textblack);
                    font-size: 14px;
                    font-weight: 400;
                    margin: 0;
                    margin-block-start: 12px;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
}

@media (max-width: 1100px) {
    // .Image_upload {
    // min-height: 500px;
    // }
}

@media (max-width: 767px) {
    .img_Upload_modal .img_upload_warp {
        min-height: auto;
    }
}

@media (max-width: 500px) {
    .img_Upload_modal .sh_btn.images_crop button {
        margin-block: 0 !important;
        // margin-inline: 5px !important;
    }
}

.cropper .cropper-hidden {
    position: fixed;
    width: 100%;
    height: 100%;
    border: 1px solid blue;
}

.cropper-hidden {
    transform-origin: rotate(90deg);
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
