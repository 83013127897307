@import '../../../../../Style.scss';

.slidedrawing_card {
    background: var(--background_white);
    border-radius: 0 0 12px 12px;
    padding-block: 20px 0;
    padding-inline: 32px 16px;
    border: 1px solid var(--border_gray);

    .inner_slidedrawing {
        width: 100%;

        .sliderdraw_wraper {
            width: 100%;
            padding-block: 6px 32px;
            padding-inline: 0px 16px;
            height: 400px;
            overflow: auto;

            .sh_box {
                background: var(--background_white);
                border-radius: 12px;
                cursor: pointer;
                border: 2px solid var(--border_gray);

                &:hover {
                    -webkit-transition: 0.5s ease-in-out;
                    -moz-transition: 0.5s ease-in-out;
                    -o-transition: 0.5s ease-in-out;
                    transition: 0.5s ease-in-out;
                    // box-shadow: 0px 28px 20px -12px rgba(0, 0, 0, 0.16),
                    //     0px 4px 20px 2px rgba(0, 0, 0, 0.02);
                }

                .content_box {
                    padding-block: 15px;
                    padding-inline: 10px;
                    width: 100%;
                    text-align: start;
                    position: relative;
                    text-align: center;

                    img {
                        max-width: 100%;
                        height: 230px;
                        object-fit: contain;
                    }

                    p {
                        font-family: var(--mali);
                        font-style: normal;
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 20px;
                        color: var(--textColor);
                    }
                }

                .sh_footer {
                    width: 100%;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: var(--background_white_85);
                    border-radius: 0px 0px 12px 12px;

                    .profile {
                        width: auto;
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .MuiAvatar-root {
                            width: 32px;
                            height: 32px;
                            font-size: 18px;
                        }
                    }

                    .like_count {
                        width: auto;
                        display: flex;
                        align-items: center;
                        gap: 0.3rem;
                        // box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05),
                        //     0px 2px 1px rgba(0, 0, 0, 0.05);
                        width: auto;

                        svg {
                            color: var(--textColor);
                            font-size: 24px;
                            display: flex;

                            &.red {
                                color: var(--heart_color);
                            }

                            &.stroke {
                                stroke: var(--textWhite);
                                stroke-width: 3px;
                            }

                            &:hover {
                                color: var(--heart_color);
                            }
                        }

                        span {
                            color: var(--textColor);
                            font-family: var(--poppin_stack);
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 14px;
                        }
                    }

                    p {
                        text-align: start;
                        font-family: var(--poppin_stack);
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 20px;
                        color: var(--activation_text);
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 140px;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
