html.rtl {
    // :lang(ar) {
    //     --poppin_stack: 'IBM Plex Sans Arabic', 'Poppins', sans-serif !important;
    // }

    // * {
    //     direction: rtl;
    // }
    .activity_choice {
        .custom_sheck {
            direction: ltr;
        }
    }

    .clasroom_header.MuiPaper-root {
        .header_classcode {
            .codenumber {
                direction: ltr;
            }
        }
    }

    .camera_modal {
        .camera_modal_inner {
            .close_modal {
                right: inherit;
                left: 20px;
            }
        }
    }

    .classpooint_joining {
        .join_card {
            .join_card_wrap {
                &.join_verifi {
                    .loginbtn button {
                        .MuiLoadingButton-loadingIndicator {
                            left: inherit;
                            right: -20px;
                        }
                    }
                }

                .profile_upload {
                    .changes_profile_images {
                        right: inherit;
                        left: 0;
                    }
                }
            }
        }
    }

    .main_body {
        .right_card .level_point_sec {
            .box {
                .box_content {
                    .point_chip {
                        inset: 8px -8px auto auto;
                        box-shadow: -2px 0px 0px #ee9900;
                    }
                }
            }
        }

        .left_card {
            .presenter_foot {
                .footright {
                    ul {
                        li {
                            &:first-child {
                                border-right: none;
                                border-left: 1px solid var(--border_gray);
                            }
                        }
                    }
                }
            }
        }
    }

    .leaderboard_modal {
        .leader_board_rank_barchart {
            .leader_board_rank_chart {
                li {
                    &:first-child {
                        & > div {
                            box-shadow: 0px 0.635542px 1.27108px rgb(0 0 0 / 5%),
                                0px 0.635542px 3.81325px 1.27108px rgb(0 0 0 / 5%), inset 12px 0px 0px rgb(0 0 0 / 25%);
                        }
                    }

                    &:nth-of-type(3) {
                        & > div {
                            box-shadow: 0px 0.635542px 1.27108px rgb(0 0 0 / 5%),
                                0px 0.635542px 3.81325px 1.27108px rgb(0 0 0 / 5%), inset -12px 0px 0px #ff9f6a;
                        }
                    }
                }
            }
        }
    }

    .quickpoll_modal {
        .quickpoll_modal_box {
            .close_modal {
                right: inherit;
                left: 36px;
            }
        }
    }

    .img_Upload_modal {
        .MuiBox-root {
            .close_modal {
                right: inherit;
                left: 36px;
            }
        }

        .searchfield {
            .MuiOutlinedInput-root {
                padding-inline-end: 10px;
            }
        }
    }

    .MuiBox-root {
        .close_modal {
            right: inherit;
            left: 36px;
        }

        &.language_modal_container {
            .close_modal {
                left: 0;
            }
        }
    }

    .slider_Upload_modal {
        .MuiBox-root {
            .close_modal {
                right: inherit;
                left: 36px;
            }
        }
    }

    .ql-editor {
        text-align: start;
    }
}
