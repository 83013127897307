@import '../../../../../Style.scss';

.card_head {
    background: var(--background_white);
    display: flex;
    // position: -webkit-sticky;
    // position: sticky;
    border-radius: 12px 12px 0 0;
    // top: 80px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 20px;
    // z-index: 111;
    border-bottom: 2px solid var(--border_gray);

    .card_head_left {
        width: auto;

        h4 {
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: 500;
            font-family: var(--poppin_stack);
            font-size: 18px;
            color: var(--textblack);

            svg {
                font-size: 24px;
            }
        }
    }

    .card_head_right {
        width: auto;
        display: flex;
        align-items: center;
        gap: 8px;

        h5 {
            font-weight: 400;
            font-family: var(--poppin_stack);
            font-size: 16px;
            color: var(--textblack);
        }

        h6 {
            font-weight: 600;
            font-family: var(--poppin_stack);
            font-size: 20px;
            color: var(--textblack);
            display: flex;
            align-items: center;
            gap: 5px;

            .red {
                color: var(--heart_color);
            }

            svg {
                font-size: 24px;
                color: var(--userSubText);

                &.red {
                    color: var(--heart_color);
                }
            }
        }
    }

    .close_modal {
        position: relative;
        cursor: pointer;
        // width: 30px;
        // height: 30px;
        // border-radius: 50%;
        // background: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        opacity: 0.8;
        width: 125px;
        color: var(--textColor);

        &:hover {
            opacity: 1;
        }
    }
}

.voting_modal {
    .MuiBox-root {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 95%;
        // height: 100vh;
        max-width: 800px;
        border: none !important;
        outline: none !important;
        // background: rgba(255, 255, 255, 0.274);
        padding: 30px;
    }

    .MuiBackdrop-root {
        background-color: var(--backDrop_bg);
    }
}

.inner_short_answer {
    .main_content {
        margin-block-start: 20px;

        &:first-child {
            margin-block-start: 0;
        }
    }

    .slide_boxs {
        margin-block-start: 20px;

        &:first-child {
            margin-block-start: 0;
        }
    }

    .video_box {
        margin-block-start: 20px;

        &:first-child {
            margin-block-start: 0;
        }
    }
}

.inner_imagesupload {
    .images_boxs {
        margin-block-start: 20px;
        background: var(--textblack);
        height: 320px;

        &:first-child {
            margin-block-start: 0;
        }
    }
}

.inner_video {
    .video_boxs {
        margin-block-start: 20px;
        background: var(--textblack);

        &:first-child {
            margin-block-start: 0;
        }
    }
}

@media (max-width: 600px) {
    .sh_footer .profile p {
        width: 100px !important;
    }

    .card_head .card_head_right h5 {
        display: none;
    }
}

@media (max-width: 500px) {
    .sh_footer .profile p {
        width: 100px !important;
    }
}
