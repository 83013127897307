@import '../../../Style.scss';

.connectFromModal {
    .MuiBox-root {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 93%;
        // height: 100vh;
        max-width: 600px;
        border: none !important;
        outline: none !important;
        background: var(--background_white);
        border-radius: 24px;
        padding: 40px !important;
        text-align: center;
    }

    p {
        font-family: var(--poppin_stack);
        color: var(--activation_text);
        font-size: 18px;
        font-weight: 500;
    }

    .MuiBackdrop-root {
        background-color: var(--backDrop_bg);
    }

    .sh_btn {
        width: 100%;
        margin-block-start: 20px;
        text-align: center;

        &.connect_btn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 24px;

            button {
                // max-width: 240px;
                margin: 0px !important;
                text-transform: none;

                &.cancel {
                    background: none;
                    color: var(--parple);

                    &:hover {
                        background: var(--lg_background);
                    }
                }

                &.default_btn {
                    color: var(--parple);
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;

                    &:hover {
                        background-color: rgba(25, 118, 210, 0.04);
                    }
                }
            }
        }

        button {
            @include activitybtn;
            max-width: 188px;
            height: 52px;
        }
    }
}

@media (max-width: 600px) {
    .connectFromModal .MuiBox-root {
        padding-block: 30px !important;
        padding-inline: 20px !important;
    }

    .connectFromModal .sh_btn.connect_btn {
        gap: 12px;
    }
}

@media (max-width: 380px) {
    .connectFromModal .sh_btn button {
        font-size: 14px;
        height: 48px;
        line-height: 18px;
    }

    .connectFromModal .sh_btn.connect_btn {
        gap: 8px;
    }
}
