*,
*::after,
*::before {
    padding: 0px;
    margin: 0px;
    outline: none;
    box-shadow: none;
    box-sizing: border-box;
}

.pointOverlay {
    &.hide {
        pointer-events: none;
    }

    position: fixed;
    z-index: 2000;
    inset: 0 0 0 0;

    display: block;
    width: 100%;
    height: 100%;

    .modal {
        width: 100%;
        height: 100%;
        background: hsla(0, 0%, 0%, 0.8);
        position: relative;
        z-index: 1;

        .contentBox {
            position: relative;
            inset: 30px 0 0 0;

            text-align: center;

            width: 50%;
            max-width: 280px;
            height: fit-content;
            padding-block: 20px 10px;
            padding-inline: 0;
            margin-block: 0;
            margin-inline: auto;
            border-radius: 16px;
            background: var(--background_white);

            .avaterBox {
                position: relative;

                width: fit-content;
                margin-block: 0;
                margin-inline: auto;
                overflow: visible;

                .avater {
                    width: 68px;
                    height: 68px;
                    border-radius: 50%;
                    margin-block: 0;
                    margin-inline: auto;
                }

                .sparkle {
                    position: absolute;
                    z-index: 2002;

                    width: 75px;

                    &:nth-of-type(1) {
                        inset: -40px 0 0 25px;
                    }

                    &:nth-of-type(2) {
                        inset: 10px 0 0 -40px;
                    }

                    &:nth-of-type(3) {
                        inset: -50px 0 0 -25px;
                    }

                    &:nth-of-type(4) {
                        inset: 10px 0 0 25px;
                    }
                }
            }

            .name {
                font-size: 18px;
                font-weight: 500;
                padding-inline: 0;
                padding-block: 8px 0;
            }

            .point {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 12px;

                img {
                    width: 28px;
                }

                .count {
                    font-size: 18px;
                    font-weight: 500;
                    padding-block: 12px;
                    padding-inline: 0;
                }
            }
        }

        .imageTopOverlay {
            position: absolute;
            inset: 0 0 0 0;
            z-index: 2001;

            width: 68px;
            height: 68px;
            border-radius: 50%;
            opacity: 0;
            transition: 0.2s all ease-out;

            &.show {
                opacity: 1;
            }

            & img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        .starGroup {
            width: fit-content;
            height: fit-content;
            margin: auto;

            .star {
                position: fixed;
                inset: 0 0 0 0;

                width: fit-content;
                height: fit-content;
                margin: auto;

                img {
                    width: 85px;
                    height: 85px;
                }
            }
        }

        .lottieGroup {
            width: 100%;
            height: 100%;

            .lottieAnimationGlobal {
                position: absolute;
                width: fit-content;
                height: fit-content;

                width: 150px !important;

                &.center {
                    transform: translate(-50%, -50%) !important;
                    top: 50%;
                    left: 50%;
                }

                svg {
                    transform: none !important;
                }
            }
        }

        .sparkle {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;
        }

        .addPoint {
            position: absolute;
            inset: 0 auto auto 0;
            margin: auto;

            font-size: 17px;

            width: 300px;
            height: 50px;
        }
    }
}

.levelUpAnimation {
    position: absolute;
    inset: 0 0 0 0;
    // z-index: -1;

    .levelBadge {
        position: absolute;
        inset: 0 0 0 0;
        z-index: 1;
        margin: auto;

        &:nth-of-type(1) {
            width: 85px;
        }

        &:nth-of-type(2) {
            width: 90px;
        }
    }

    .levelText {
        position: absolute;
        inset: 40px 0 0 0;
        z-index: 1;

        font-size: 25px;
        font-weight: 600;
        color: var(--button_white_text);
        letter-spacing: 3px;

        width: fit-content;
        height: fit-content;
        margin: auto;
        text-shadow: -1px -1px 0 #e4aa31, 1px -1px 0 #e4aa31, -1px 1px 0 #e4aa31, 1px 1px 0 #e4aa31;

        &:nth-of-type(2) {
            font-size: 26px;
            font-weight: 700;
            text-shadow: -2px -2px 30px #e4aa31, 2px -2px 30px #e4aa31, -2px 2px 30px #e4aa31, 2px 2px 30px #e4aa31;
        }
    }

    .sparkleContainer {
        position: absolute;
        inset: 0 0 0 0;
        z-index: 0;
        margin: auto;

        .positionCheck {
            position: relative;

            .sparkle {
                position: absolute;
                inset: 7vh 0 0 0;
                margin: auto;

                &:nth-of-type(1) {
                    width: 500px;
                }

                &:nth-of-type(2) {
                    width: 600px;
                }
            }
        }
    }

    .closeBtn {
        position: absolute;
        inset: 0 0 0 0;
        z-index: 1;
        margin: auto;

        font-size: 16px;
        font-weight: 600;
        color: var(--button_white_text);

        width: 60px;
        height: 38px;
        border-radius: 100px;
        background: var(--backBlack_02);
        border: none;
        outline: none;
        cursor: pointer;

        &:hover {
            color: var(--textblack);
            background: var(--backwhite_02);
        }
    }
}
