@mixin activitybtn {
    background: var(--btn_gradient);
    color: var(--button_white_text);
    width: 100%;
    height: 52px;
    border-radius: 50px;
    box-shadow: none;
    font-family: var(--poppin_stack);
    font-size: 16px;
    font-weight: var(--fw_medium);
    max-width: 324px;
    margin-block: 0;
    margin-inline: auto;
    box-shadow: none !important;
    text-transform: none;
}

@mixin prime {
    background: var(--btn_gradient);
    color: var(--button_white_text);
    width: 180px;
    height: 40px;
    border-radius: 50px;
    box-shadow: none;
    font-family: var(--poppin_stack);
    font-size: 16px;
    font-weight: var(--fw_medium);
    margin-block: 0;
    box-shadow: none !important;
    text-transform: none;
}

// Flexbox
@mixin displayFlex($dir, $align, $justify) {
    display: flex;
    flex-direction: $dir;
    align-items: $align;
    justify-content: $justify;
}

// Media Query
@mixin min($screen) {
    @media (min-width: $screen) {
        @content;
    }
}

@mixin max($screen) {
    @media (max-width: $screen) {
        @content;
    }
}
