@import '../../../../Style.scss';

.clasroom_header.MuiPaper-root {
    background-color: var(--background_white);
    box-shadow: none;

    .container {
        max-width: var(--container-size);
        width: 100%;
        margin-block: 0;
        margin-inline: auto;
        padding-block: 0;
        padding-inline: 40px;
        min-height: 80px;
    }

    .header_classcode {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        .codenumber {
            display: flex;
            align-items: center;

            span {
                width: 30px;
                height: 40px;
                border-radius: 6px;
                background: var(--checkboxColor);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-block: 0;
                margin-inline: 2px;
                font-family: var(--poppin_stack);
                color: var(--textblack);
                font-size: 20px;
                font-weight: 600;
            }
        }

        h6 {
            font-family: var(--poppin_stack);
            color: var(--textblack);
            font-size: 16px;
            font-weight: 500;
            display: flex;
            align-items: center;
        }
    }

    .header_logo {
        display: flex;
        align-items: center;
        gap: 0.6rem;

        width: max-content;
        padding-inline-start: 0;

        span {
            font-family: var(--poppin_stack);
            color: var(--parple);
            font-size: 20px;
            font-weight: 600;
        }
    }

    .user_badge {
        display: flex;
        align-items: center;
        color: var(--textblack);
        padding: 10px;
        border-radius: 6px;
        gap: 0.5rem;
        width: fit-content;
        margin-inline-start: auto;

        .MuiAvatar-circular {
            width: 32px;
            height: 32px;
        }

        p {
            font-weight: 500;
            font-family: var(--poppin_stack);
            font-size: 16px;
        }
    }
}

.user_menu {
    .MuiPaper-root {
        top: 64px !important;
    }
}

.header_logo {
    padding-block: 0 !important;
    padding-inline: 0 !important;

    img {
        width: 100%;
        max-height: 40px;
        object-fit: contain;

        @include max(700px) {
            max-width: 70px;
            padding-inline-end: 5px;
        }
        @include max(450px) {
            max-width: 60px;
        }
    }
}

.box_align {
    align-items: center;

    & > :is(.logo_box, .hambar_box) {
        flex: 1 1 27%;

        @include max(900px) {
            flex: 1 1 0%;
        }
        @include max(768px) {
            max-width: 75px;
        }
        @include max(576px) {
            width: 50px;
        }
    }

    & > .header_classcode {
        flex: 1 1 60%;
    }
}

.top_header .MuiToolbar-gutters {
    padding-inline: 0;
    min-height: auto;
}

.header_logo,
.user_badge {
    // padding-block: 1.46rem;
    padding-inline: 10px;
}

.user_badge:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.user_badge {
    cursor: pointer;
}

.nav_menu.MuiModal-root {
    .MuiBackdrop-root {
        background-color: transparent;
    }

    .MuiPaper-root {
        box-shadow: 0px 28px 20px -12px rgba(0, 0, 0, 0.16), 0px 4px 20px 2px rgba(0, 0, 0, 0.02);
        padding: 0;
        border-radius: 12px;
        min-width: 228px;

        ul {
            padding: 0;

            li {
                display: flex;
                align-items: center;
                gap: 12px;
                isolation: isolate;
                background: var(--background_white);
                min-height: 42px;
                padding-block: 6px;
                padding-inline: 20px;
                font-family: var(--poppin_stack);
                font-weight: 500;
                font-size: 14px;
                color: var(--textColor);

                svg {
                    font-size: 18px;
                    color: var(--parple);
                }

                &:hover {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)),
                        var(--background_white);
                }

                .MuiTouchRipple-root {
                    z-index: -1;

                    .MuiTouchRipple-child {
                        background-color: var(--Ripplebg_color);
                    }
                }
            }

            .leaveclass {
                color: var(--chatred);

                &:hover {
                    background: var(--light_red_bg);
                }

                .MuiTouchRipple-root {
                    z-index: -1;

                    .MuiTouchRipple-child {
                        background-color: var(--red_ripple_color);
                    }
                }
            }
        }
    }
}
