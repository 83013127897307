@import '../../../../Style.scss';

.main_body {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 48px);
    padding-block-start: 100px;
    padding-block-end: 40px;
    position: relative;

    .left_grid {
        @include min(901px) {
            flex: 1;
        }
    }

    .right_grid {
        @include min(901px) {
            min-width: 252px;
        }
    }

    & + .login_foot {
        width: 100%;
        text-align: center;
        padding: 10px;
        background: var(--classroombg);

        // @include min(600px) {
        // padding: 10px;
        // }

        .foot_login_body {
            color: var(--join_foot_gray);
            font-size: 12px;
            font-family: var(--poppin_stack);
            font-weight: 400;
            padding-block-end: 10px;

            // @include min(600px) {
            // padding: 10px;
            // }

            a {
                font-size: 12px;
                font-family: var(--poppin_stack);
                font-weight: 400;
                color: var(--join_foot_gray);
                text-decoration: underline !important;

                &:hover {
                    color: var(--parple);
                }
            }
        }
    }

    .container {
        max-width: var(--container-size);
        width: 100%;
        margin-block: 0;
        margin-inline: auto;
        padding-block: 0;
        padding-inline: 40px;
    }

    .left_card {
        background: var(--background_white);
        border-radius: 12px;

        .presenter_view {
            display: flex;
            align-items: center;
            justify-content: center;

            // height: 504px;
            // margin-top: 24px;
            flex-direction: column;

            .presenter_inner {
                width: 100%;
                // padding: 20px;
            }
        }

        .loader_main {
            width: 100%;

            .MuiLinearProgress-root {
                height: 6px;
                background: var(--progrss_gray);

                .MuiLinearProgress-bar {
                    background: var(--parple);
                }
            }
        }

        .presenter_img {
            width: 100%;

            .text {
                display: flex;
            }

            &.loading_page {
                padding-block-end: 56.25%;
                margin-block: 0;
                margin-inline: auto;
                position: relative;

                .text {
                    position: absolute;

                    align-items: center;
                    justify-content: center;
                    top: 0;
                    left: 0;
                    margin-block: 0;
                    width: 100%;
                    bottom: 0;
                    right: 0;
                    margin-block: 0;
                    margin-inline: auto;
                    flex-direction: column;

                    img {
                        width: 50%;
                    }

                    h6 {
                        font-family: var(--poppin_stack);
                        color: var(--textColor);
                        font-size: 20px;
                        font-weight: 500;
                    }
                }
            }

            &.audienceimage {
                padding-block-end: 56.25%;
                margin-block: 0;
                margin-inline: auto;
                position: relative;

                .text {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 0;
                    left: 0;
                    margin-block: 0;
                    margin-inline: auto;
                    width: 100%;
                    bottom: 0;
                    right: 0;
                    margin-block: 0;
                    margin-inline: auto;
                    flex-direction: column;
                }

                img {
                    width: 32%;
                    border-radius: 0;
                }

                h6 {
                    font-family: var(--poppin_stack);
                    color: var(--textColor);
                    font-size: 20px;
                    font-weight: 500;
                    margin-block-start: 05%;
                }
            }

            img {
                width: 100%;
                object-fit: contain;
                // max-height: 440px;
                border-radius: 12px 12px 0 0;

                &.notslideimage {
                    height: 280px;
                }
            }
        }

        .presenter_text {
            h6 {
                font-family: var(--poppin_stack);
                color: var(--textColor);
                font-size: 20px;
                font-weight: 500;
            }
        }

        .presenter_foot {
            padding-block: 24px;
            padding-inline: 20px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .fullscreen {
                cursor: pointer;
                color: var(--parple);
            }

            .footright {
                width: 112px;
                padding-block: 13px;
                padding-inline: 9px;
                border: 1px solid #ecf0f4;
                border-radius: 12px;

                ul {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    li {
                        display: inline-block;
                        list-style: none;
                        padding-block: 0;
                        padding-inline: 12px;
                        cursor: pointer;
                        color: var(--parple);

                        svg {
                            display: flex;
                        }

                        &:first-child {
                            &.showBorder {
                                border-right: 1px solid var(--border_gray);
                            }
                        }
                    }
                }
            }

            .footleft {
                // width: 80px;
                .pfoot_profile {
                    display: flex;
                    align-items: flex-start;
                    gap: 20px;

                    // .pfoot_profile_img{
                    //     border: 4px solid var(--caption_border);
                    //     border-radius: 50%;
                    // }
                    .MuiAvatar-root {
                        width: 46px;
                        height: 46px;
                        border: 4px solid var(--caption_border);
                        min-height: 26px;
                    }

                    .pfoot_profile_content {
                        text-align: start;

                        .presenterIsCCT {
                            display: flex;
                            align-items: center;
                            gap: 12px;
                            min-height: 26px;

                            a {
                                display: flex;
                            }
                            .badge {
                                @include displayFlex(row, center, center);
                                gap: 8px;
                                img {
                                    height: 20px;
                                    cursor: pointer;
                                }
                            }
                        }

                        h4 {
                            font-family: var(--poppin_stack);
                            color: var(--activation_text);
                            font-size: 18px;
                            line-height: 1.2;
                            font-weight: 500;
                        }

                        p {
                            font-family: var(--poppin_stack);
                            color: var(--userSubText);
                            font-size: 13px;
                            line-height: 1.2;
                            font-weight: 400;
                            margin-block-start: 0;
                        }
                    }
                }
            }

            .responce_icon {
                width: auto;
                flex: 1;

                ul {
                    li {
                        display: inline-block;
                        list-style: none;
                        margin-block: 0;
                        margin-inline: 6px;
                        cursor: pointer;

                        .hand {
                            background: var(--classroombg);
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                            padding-block: 10px;
                            padding-inline: 12px;
                            border-radius: 12px;
                        }
                    }
                }
            }
        }
    }

    .right_card {
        background: var(--background_white);
        border-radius: 12px;
        // padding-block: 36px;
        padding-block: 20px;
        padding-inline: 20px;

        .rc_profile {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 12px;
        }

        .avater_images {
            width: auto;

            .MuiAvatar-root {
                width: 76px;
                height: 76px;
                margin-block: 0;
                margin-inline: auto;
                // border: 4px solid #cfd5ff;
                font-size: 30px;

                img {
                    width: 76px;
                    height: 76px;
                }

                @include max(599px) {
                    width: 64px;
                    height: 64px;

                    img {
                        width: 64px;
                        height: 64px;
                    }
                }
            }
        }

        .profilename {
            width: 100%;
            flex: 1;
            text-align: center;

            .pro_user_profile_data {
                @include displayFlex(row, flex-end, flex-end);
                margin-block-start: 5px;
                gap: 8px;

                .level_icon {
                    width: 52px;
                    height: 52px;

                    @include max(900px) {
                        width: 46px;
                        height: 46px;
                        display: none;
                    }
                }

                @include max(900px) {
                    margin-block-start: 0px;
                }

                .Pro_user_content {
                    flex: 1;
                    text-align: start;

                    .current_level {
                        justify-content: center;
                        text-align: center;
                    }
                }
            }

            h4 {
                font-family: var(--poppin_stack);
                color: var(--activation_text);
                font-size: 18px;
                line-height: 1.3;
                font-weight: 500;
            }

            .details {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 2px;

                @include max(900px) {
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 10px;
                }

                .groupName {
                    font-size: 12px;
                    font-weight: 500;
                    color: #ffffff;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    width: max-content;
                    max-width: 125px;
                    padding-block: 2px;
                    padding-inline: 10px;
                    border-radius: 100px;
                    overflow: hidden;
                }
            }

            p {
                font-family: var(--poppin_stack);
                color: var(--userSubText);
                font-size: 16px;
                font-weight: 500;
                margin-block-start: 4px;
            }
        }

        .current_level {
            width: 100%;
            text-align: center;
            margin-block-start: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-family: var(--poppin_stack);
                color: var(--grey_text_939094);
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
            }
        }

        .level_point_sec {
            margin-block-start: 28px;
            display: flex;
            align-items: flex-start;
            gap: 12px;
            flex-direction: column;

            .box {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 1;
                background: var(--checkboxColor);
                padding-block: 0;
                padding-inline: 20px;
                border-radius: 12px;
                min-height: 40px;
                gap: 8px;

                &.level_box {
                    display: none;

                    @include max(900px) {
                        display: flex;

                        .box_content {
                            img {
                                height: 44px;
                            }
                        }
                    }
                }

                .box_content {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 6px;

                    img {
                        // max-width: 75%;
                        height: 24px;
                    }

                    h5 {
                        font-family: var(--poppin_stack);
                        color: var(--textColor);
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 17px;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                    }

                    .point_chip {
                        position: absolute;
                        inset: 8px auto auto -8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 14px;
                        width: 14px;
                        background: var(--point_chip_bg);
                        border-radius: 50%;
                        font-family: var(--poppin_stack);
                        color: var(--textColor);
                        font-weight: 600;
                        box-shadow: 2px 0px 0px #ee9900;

                        svg {
                            font-size: 11px;
                            display: flex;
                        }
                    }
                }

                .text {
                    p {
                        font-family: var(--poppin_stack);
                        color: var(--foot_gray);
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 1.3;
                    }
                }
            }
        }

        .cpprogressbar {
            .MuiLinearProgress-root {
                height: 8px;
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)), #ffffff;
                margin-block-start: 14px;
                border-radius: 8px;

                .MuiLinearProgress-bar {
                    // background-image: linear-gradient(
                    //     135deg,
                    //     #6378ff 31.82%,
                    //     #99c2ff 31.82%,
                    //     #99c2ff 50%,
                    //     #6378ff 50%,
                    //     #6378ff 81.82%,
                    //     #99c2ff 81.82%,
                    //     #99c2ff 100%
                    // );
                    // background-size: 15.56px 15.56px;
                    background: linear-gradient(75.08deg, #ef402d 0.4%, #f6b223 90.9%);
                }
            }
        }

        .MuiDivider-root {
            margin-block: 30px 24px;
            // border-color: var(--border_gray);
            // border-width: 1px;
            border: none;
        }

        .ctc_badge {
            width: 85%;
            margin-block: 12px 0;
            margin-inline: auto;
            display: flex;
            align-items: center;
            background: var(--parple_08);
            border-radius: 8px;
            padding: 8px;
            justify-content: center;
            gap: 1rem;

            img {
                width: 24px;
            }

            span {
                font-family: var(--poppin_stack);
                color: var(--textColor);
                font-size: 13px;
                font-weight: 300;
            }
        }

        .participant_joined_wrapper {
            display: flex;
            gap: 0.7rem;
            align-items: center;
            justify-content: center;

            span {
                padding-block: 8px;
                padding-inline: 12px;
                background: var(--caption_border);
                color: #2a326b;
                font-size: 16px;
                font-weight: 500;
                font-family: var(--poppin_stack);
                border-radius: 20px;
                line-height: 16px;
                // &.red_span {
                //     background: Style.var(--red)-span-bg;
                //     color: Style.var(--red)span-color;
                // }
            }

            h2 {
                font-family: var(--poppin_stack);
                color: var(--textColor);
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    .activity_choice {
        .active_title_head {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 1rem;

            img {
                filter: var(--filter_invert);
            }

            h4 {
                font-family: var(--poppin_stack);
                color: var(--textColor);
                font-size: 16px;
                font-weight: 500;
            }

            @include max(900px) {
                display: none;
            }
        }

        .short_ans_head {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 1rem;

            img {
                filter: var(--filter_invert);
            }

            h4 {
                font-family: var(--poppin_stack);
                color: var(--textColor);
                font-size: 16px;
                font-weight: 500;
            }
        }

        .short_ans_form {
            width: 100%;
            margin-block-start: 6px;
        }

        .MuiDivider-root {
            margin-block: 18px 24px;
            border-color: var(--border_gray);
            border-width: 1px;

            @include max(900px) {
                display: none;
            }
        }

        .type_ans_warp {
            width: 100%;
            // min-height: 558px;
            display: flex;
            // gap: 1rem;
            flex-direction: column;
            justify-content: space-between;

            .type_ans_box {
                width: 100%;
                display: flex;
                align-items: flex-start;
                gap: 1rem;

                img {
                    width: 60px;
                    height: 60px;
                    border-radius: 30px;
                }

                h5 {
                    text-align: start;
                    font-family: var(--poppin_stack);
                    color: var(--para_color);
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }

    .left_card .presenter_foot .responce_icon ul li:first-child {
        display: none;
    }

    @include max(530px) {
        padding-block-end: 10px;
    }
}

.chatbot_sec {
    width: 100%;
    // min-height: 560px;
    display: flex;
    // gap: 1rem;
    flex-direction: column;
    justify-content: space-between;

    &.justify_end {
        justify-content: flex-end;
    }

    // .Video_upload {
    //     margin-top: 6px;
    // }

    .chatbox .type_ans_box.colum_rev .chat_text {
        &.update_images {
            display: grid;
            padding: 0;
            overflow: hidden;

            //    max-height: 235px;
            &.chatImageUpload {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                border-radius: 12px;
                border: 1px solid var(--response_border_color);
                // outline: 2px solid #fff;
                // outline-offset: -1px;
            }

            .video_response_box {
                width: 100%;
                position: relative;
                min-width: 160px;

                &.fixed_height_ios {
                    // overflow: hidden;
                    height: 250px;

                    .video_player {
                        height: 250px !important;
                        max-height: inherit;
                    }
                }
            }

            .video_player {
                border-radius: 12px 12px 0 0;
                overflow: hidden;
                background: var(--background_black);
                cursor: pointer;
                width: 100% !important;
                height: 100% !important;
                max-height: 400px;
            }

            .video_icon {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 48px;
                height: 48px;
                margin: auto;

                svg {
                    display: flex;
                    font-size: 48px;
                    color: var(--textWhite);
                    cursor: pointer;
                }
            }

            img {
                width: 100%;
                height: 100%;
                min-height: 84px;
                max-height: 235px;
                object-fit: cover;
                // background-color: var(--background_black);
                border-radius: 12px 12px 0 0;
                cursor: pointer;
            }

            h5 {
                width: 100%;
                margin-inline-end: auto;
                text-align: start;
                padding: 12px;

                &.mali {
                    font-family: var(--mali);
                }
            }
        }

        &.update_audio {
            padding: 12px;
        }

        &.slidedrawing {
            padding: 0;
            overflow: hidden;
            //    max-height: 235px;
            border: 1px solid var(--response_border_color);
            display: flex;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                max-height: 200px;
                object-fit: cover;
                border-radius: 12px 12px 0 0;
            }

            h5 {
                padding: 12px;
            }
        }

        h5 {
            font-size: 15px;
            font-weight: 400;

            height: max-content;
            max-height: 300px;
            overflow: auto;
        }
    }

    .sh_btn button {
        max-width: 100%;
    }
}

.sh_btn {
    width: 100%;
    margin-block-start: 16px;

    button {
        background: var(--btn_gradient);
        color: var(--textWhite);
        width: 100%;
        height: 48px;
        border-radius: 50px;
        box-shadow: none;
        font-family: var(--poppin_stack);
        font-size: 16px;
        font-weight: 500;
        text-transform: none;

        &.canceldeep {
            background: var(--chatred);
            color: var(--textWhite);

            &:hover {
                background: var(--chatred);
            }
        }
    }
}

.activity_choice {
    // min-height: 670px;
    background: var(--background_white);
    border-radius: 12px;
    padding: 20px;
    padding-block-end: 30px;
    text-align: center;

    .type_ans_warp {
        width: 100%;
        // min-height: 558px;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: space-between;

        .type_ans_box {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 1rem;

            img {
                width: 60px;
                height: 60px;
                border-radius: 30px;
            }

            h5 {
                text-align: start;
                font-family: var(--poppin_stack);
                color: var(--para_color);
                font-size: 16px;
                font-weight: 500;
            }
        }

        .sh_btn {
            width: 100%;
            margin-block-start: 16px;

            button {
                background: var(--btn_gradient);
                color: var(--textWhite);
                width: 100%;
                height: 48px;
                border-radius: 50px;
                box-shadow: none;
                font-family: var(--poppin_stack);
                font-size: 16px;
                font-weight: 500;
                text-transform: none;
            }
        }
    }

    .multiple_chooses_error_massage {
        width: 100%;
        display: block;

        span {
            text-align: start;
            // font-family: var(--font-stack);
            font-family: var(--poppin_stack);
            color: var(--error_color);
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.66;
            display: block;
        }
    }

    .custom_sheck {
        width: 100%;
        display: flex;
        align-items: center;
        // gap: 1rem;
        flex-wrap: wrap;
        margin-block-start: -6px;
    }

    label {
        cursor: pointer;
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
        display: block;
        width: 50%;
        margin-block-start: 12px;

        &:nth-of-type(odd) {
            padding-inline-end: 6px;
        }

        &:nth-of-type(even) {
            padding-inline-start: 6px;
        }

        @media (max-width: 991px) {
            width: 25%;
            padding-inline: 6px;
        }

        &.blue {
            color: var(--checkboxBlue);

            input {
                &:checked + span {
                    background: var(--checkboxBlue);
                }
            }
        }

        &.green {
            color: var(--checkboxGreen);

            input {
                &:checked + span {
                    background: var(--checkboxGreen);
                }
            }
        }

        &.pink {
            color: var(--checkboxPink);

            input {
                &:checked + span {
                    background: var(--checkboxPink);
                }
            }
        }

        &.yellow {
            color: var(--checkboxYellow);

            input {
                &:checked + span {
                    background: var(--checkboxYellow);
                }
            }
        }

        &.purple {
            color: var(--checkboxPurple);

            input {
                &:checked + span {
                    background: var(--checkboxPurple);
                }
            }
        }

        &.deepgreen {
            color: var(--deepgreen);

            input {
                &:checked + span {
                    background: var(--deepgreen);
                }
            }
        }

        &.deeporange {
            color: var(--deeporange);

            input {
                &:checked + span {
                    background: var(--deeporange);
                }
            }
        }

        &.orange {
            color: var(--orange);

            input {
                &:checked + span {
                    background: var(--orange);
                }
            }
        }

        span {
            position: relative;
            border-radius: 8px;
            transition: 0.4s;
            padding-block: 0;
            padding-inline: 15px;
            height: 48px;

            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 7px;
            background: var(--checkboxColor);
            font-weight: 600;
            font-size: 20px;
        }

        input {
            pointer-events: none;
            display: none;

            &:checked + span {
                color: var(--textWhite);
            }
        }
    }
}

.answer_area {
    width: 100%;

    // margin-top: 160px;
    textarea {
        width: 100%;
        height: 272px !important;
        border: 2px solid var(--border_gray);
        border-radius: 12px;
        padding-block: 20px;
        padding-inline: 24px;
    }
}

.iconbtn_sec {
    position: relative;
    width: auto;

    .icon_btn {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        color: var(--textblack);

        svg {
            font-size: 15px;
        }
    }

    .other_icon {
        position: absolute;
        top: -70px;
        left: 0;
        padding: 10px;
        background: var(--background_white);
        display: flex;
        align-items: center;
        gap: 0.5rem;
        border-radius: 12px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.05);
        transition: transform 200ms ease;
        opacity: 1;
        transform: scale(1);

        &.close {
            opacity: 0;
            transform: scale(0);
        }
    }
}

.chatbox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // min-height: 150px;
    // overflow: hidden auto;
    margin-block-end: 24px;
    padding-block: 2px;
    padding-inline: 0;

    .type_ans_box {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 1rem;

        .audio_record {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding-block: 0;

            .audio_timer {
                display: none;
            }

            .audio_wav {
                flex: 1;
                padding-inline-start: 0px;
            }

            .bttn {
                button {
                    width: 40px;
                    height: 40px;
                    background: var(--active_bg);
                    border-radius: 48px;
                    text-transform: none;

                    svg {
                        font-size: 20px;
                        color: var(--activation_text_49454f);
                    }
                }
            }
        }

        .fillin_answer {
            ul {
                li {
                    display: block;
                    margin-block-start: 12px;

                    &:first-child {
                        margin-block-start: 0;
                    }

                    .fiilform {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        gap: 0.7rem;

                        .field {
                            flex: 1;

                            p {
                                text-align: start;
                                font-size: 14px;
                                line-height: 24px;
                                border-bottom: 1px solid var(--active_bg);
                                font-weight: 500;
                                color: var(--activation_text);
                                font-family: var(--poppin_stack);
                                word-break: break-word;
                            }
                        }

                        .number {
                            width: auto;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            background: var(--background_cfd5ff);
                            border-radius: 20px;
                            width: 24px;
                            height: 20px;

                            p {
                                color: var(--parple);
                                font-family: var(--poppin_stack);
                                font-size: 14px;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }

        &.colum_rev {
            flex-direction: row-reverse;

            .chat_text_group {
                align-items: flex-end;
                flex: inherit;
                width: fit-content;
                max-width: 70%;
                min-width: 30%;
            }

            .MuiAvatar-root {
                width: 40px;
                height: 40px;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            .chat_text {
                border-radius: 12px;
                padding: 12px;
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)),
                    var(--background_white);

                &.mali {
                    h5 {
                        font-family: var(--mali);

                        * {
                            font-family: var(--mali);
                        }

                        p {
                            font-family: var(--mali);
                        }

                        span {
                            font-family: var(--mali);
                        }

                        u {
                            font-family: var(--mali);
                        }

                        @media (prefers-color-scheme: dark) {
                            & *[style='color: blue;'] {
                                color: var(--blue_clr) !important;
                            }

                            & *[style='color: red;'] {
                                color: var(--red_clr) !important;
                            }
                        }
                    }
                }
            }
        }

        .MuiAvatar-root {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            // background: none;
        }

        img {
            width: 52px;
            height: 52px;
            border-radius: 30px;
        }

        h5 {
            text-align: start;
            font-family: var(--poppin_stack);
            color: var(--para_color);
            font-size: 15px;
            // font-weight: 500;
            word-break: break-word;

            * {
                text-align: start;
                font-family: var(--poppin_stack);
                font-size: 15px;
                word-break: break-word;
            }

            strong,
            b {
                font-weight: bold;
            }

            p {
                color: var(--para_color);
                font-weight: 400;
                font-size: 15px;
                text-align: start;
                font-family: var(--poppin_stack);
                word-break: break-word;
            }

            ul,
            ol {
                padding-inline-start: 20px;
            }

            ul {
                li {
                    list-style: disc;
                }
            }

            ol {
                li {
                    list-style: decimal;
                }
            }

            span {
                text-align: start;
                font-family: var(--poppin_stack);
                font-size: 15px;
            }

            u {
                text-align: start;
                font-family: var(--poppin_stack);
                font-size: 15px;
            }

            .error {
                color: var(--chatred);
            }
        }

        .chat_text_group {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .chat_text {
            // max-width: 254px;
            width: 100%;
            text-align: start;
            font-family: var(--poppin_stack);
            font-size: 15px;
            color: var(--activation_text);
            position: relative;
        }

        .multi_ans {
            width: 100%;
            display: flex;
            gap: 4px;
            flex-wrap: wrap;

            &.quickPool {
                span {
                    color: var(--textWhite);
                    height: 29px;
                    font-weight: 400;
                }
            }

            span {
                height: 28px;
                border-radius: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 52px;
                font-size: 15px;
                font-family: var(--poppin_stack);
                font-weight: 600;
                padding-block: 0;
                padding-inline: 12px;

                &.green {
                    background: var(--checkboxGreen);
                    color: var(--textWhite);
                }

                &.pink {
                    background: var(--checkboxPink);
                    color: var(--textWhite);
                }

                &.blue {
                    background: var(--checkboxBlue);
                    color: var(--textWhite);
                }

                &.yellow {
                    background: var(--checkboxYellow);
                    color: var(--textWhite);
                }

                &.purple {
                    background: var(--checkboxPurple);
                    color: var(--textWhite);
                }

                &.deepgreen {
                    background: var(--deepgreen);
                    color: var(--textWhite);
                }

                &.deeporange {
                    background: var(--deeporange);
                    color: var(--textWhite);
                }

                &.orange {
                    background: var(--orange);
                    color: var(--textWhite);
                }
            }
        }
    }
}

.chatbox {
    .big_avatar {
        .chat_text {
            max-width: 100%;
        }
    }
}

// fill in the blank
.fill_blanks {
    background: var(--background_white);
    border-radius: 12px;
    text-align: center;
    margin-block-start: 6px;

    .fill_blanks_head {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;

        h4 {
            font-family: var(--poppin_stack);
            color: var(--textColor);
            font-size: 16px;
            font-weight: 500;
        }
    }

    .MuiDivider-root {
        margin-block: 18px 24px;
        border-color: var(--border_gray);
        border-width: 1px;
    }

    .fill_form_warp {
        width: 100%;
        padding-inline: 16px;
        padding-block: 0 8px;

        .error_sec {
            width: 100%;
            text-align: start;
            margin-block-start: 4px;
            color: var(--error_color);

            &:empty {
                display: none;
            }

            span {
                text-align: start;
                // font-family: var(--font-stack);
                font-family: var(--poppin_stack);
                color: var(--error_color);
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 1.66;
                display: block;
            }
        }

        ul {
            li {
                display: block;
                margin-block-start: 12px;

                &.active {
                    .fiilform {
                        .number {
                            background: var(--parple);

                            p {
                                color: var(--textWhite);
                            }
                        }
                    }
                }

                .fiilform {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 0.7rem;

                    .field {
                        flex: 1;

                        .MuiFormControl-root {
                            width: 100%;

                            input {
                                color: var(--textColor);
                                font-family: var(--poppin_stack);
                                font-size: 14px;
                                font-weight: 400;
                                caret-color: var(--parple);
                            }

                            .MuiInput-underline::before {
                                border-bottom: 1px solid var(--active_bg);
                            }

                            .MuiInput-underline::after {
                                border-bottom: 1px solid var(--parple);
                            }
                        }
                    }

                    .number {
                        width: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: var(--checkboxColor);
                        border-radius: 4px;
                        width: 30px;
                        height: 30px;

                        p {
                            color: var(--activation_text);
                            font-family: var(--poppin_stack);
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .type_ans_warp {
        width: 100%;
        // min-height: 558px;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: space-between;

        .type_ans_box {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 1rem;

            img {
                width: 60px;
                height: 60px;
                border-radius: 30px;
            }

            h5 {
                text-align: start;
                font-family: var(--poppin_stack);
                color: var(--para_color);
                font-size: 16px;
                font-weight: 500;
            }
        }

        .sh_btn {
            width: 100%;
            margin-block-start: 16px;

            button {
                background: var(--btn_gradient);
                color: var(--textWhite);
                width: 100%;
                height: 48px;
                border-radius: 50px;
                box-shadow: none;
                font-family: var(--poppin_stack);
                font-size: 16px;
                font-weight: 500;
                text-transform: none;
            }
        }
    }

    .custom_sheck {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;
    }

    label {
        cursor: pointer;
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
        display: block;
        width: 47%;

        &.blue {
            color: var(--checkboxBlue);

            input {
                &:checked + span {
                    background: var(--checkboxBlue);
                }
            }
        }

        &.green {
            color: var(--checkboxGreen);

            input {
                &:checked + span {
                    background: var(--checkboxGreen);
                }
            }
        }

        &.pink {
            color: var(--checkboxPink);

            input {
                &:checked + span {
                    background: var(--checkboxPink);
                }
            }
        }

        &.yellow {
            color: var(--checkboxYellow);

            input {
                &:checked + span {
                    background: var(--checkboxYellow);
                }
            }
        }

        span {
            position: relative;
            border-radius: 8px;
            transition: 0.4s;
            padding-block: 0;
            padding-inline: 15px;
            height: 48px;

            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 7px;
            background: var(--checkboxColor);
            font-weight: 600;
            font-size: 20px;
        }

        input {
            pointer-events: none;
            display: none;

            &:checked + span {
                color: var(--textWhite);
            }
        }
    }
}

// short answer
.activity_choice {
    .short_ans_form {
        .jodit-react-container {
            .jodit-placeholder {
                padding: 20px;
            }

            .jodit-wysiwyg {
                text-align: start;
                min-height: 162px !important;
                padding: 20px;

                ul {
                    padding-inline-start: 22px;

                    li {
                        list-style: inherit;
                    }
                }

                ol {
                    padding-inline-start: 22px;

                    li {
                        list-style: inherit;
                    }
                }
            }
        }

        .jodit-workplace + .jodit-status-bar:not(:empty) {
            display: none;
        }

        // .jodit-workplace{
        //     height: 162px !important;
        // }
        .jodit-toolbar__box:not(:empty) {
            background-color: var(--background_white);
            border-bottom: none;
            border-top: 1px solid var(--border_gray);
            border-radius: 0px 0px 12px 12px;
            overflow: hidden;

            span.jodit-toolbar-button__trigger {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                opacity: 0;
                z-index: -1;

                svg {
                    font-size: 0;
                }
            }
        }

        .jodit-toolbar-editor-collection .jodit-toolbar-button {
            position: relative;

            &:last-child {
                span.jodit-toolbar-button__trigger {
                    z-index: 1;
                }
            }
        }

        .error {
            margin-block-start: 4px;

            .jodit-container:not(.jodit_inline) {
                border-color: var(--error_color);
            }
        }

        .jodit-container:not(.jodit_inline) {
            border: 2px solid var(--border_gray);
            border-radius: 12px;
            display: flex;
            flex-direction: column-reverse;
        }
    }
}

.jodit-color-picker__extra {
    display: none;
}

.jodit-color-picker__groups {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 12px;

    .jodit-color-picker__color-item {
        border-radius: 50%;
        width: 14px;
        height: 14px;
        cursor: pointer;
    }
}

.jodit-tabs .jodit-tabs__buttons {
    display: none !important;
}

// audio
.audio_record {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-block: 15px;
    gap: 15px;

    .audio_timer {
        width: 48px;
        background-color: var(--background_white);
        color: var(--textColor);
        border-radius: 12px;
        font-family: var(--poppin_stack);
    }

    .audio_wav {
        flex: 1;
        padding-inline-start: 8px;

        wave {
            cursor: pointer !important;

            canvas {
                border: none;
            }
        }
    }

    .bttn {
        button {
            width: 40px;
            height: 40px;
            background: var(--active_bg);
            border-radius: 48px;
            text-transform: none;
            color: var(--activation_text_49454f);

            svg {
                font-size: 20px;
                color: var(--activation_text_49454f);
            }
        }
    }
}

.wave_section {
    padding-block: 0;
    padding-inline: 22px;
    background: var(--checkboxColor);
    border-radius: 12px;
}

.sh_btn {
    width: 100%;
    margin-block-start: 16px;
    text-align: center;

    &.images_crop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;

        button {
            // max-width: 240px;
            // margin: 0px !important;
            text-transform: none;

            &.cancel {
                background: var(--cancelbtn);
                color: var(--error_color);
            }
        }

        .MuiLoadingButton-root {
            color: var(--button_white_text);
        }

        .MuiLoadingButton-startIconLoadingStart {
            display: none;
        }

        .MuiLoadingButton-loadingIndicator {
            position: relative;
            left: inherit;
            // margin-inline-end: 10px;
        }
    }

    button {
        @include activitybtn;

        &.cancel {
            background: var(--cancelbtn);
            color: var(--error_color);

            &.cancel_disable {
                background: var(--checkboxColor);
                color: #c9c5ca;
                cursor: default;
            }
        }
    }
}

.audio_upload_buttons {
    width: 100%;
    margin-block-start: 6px;

    .image_upload_grid {
        display: flex;
        align-items: center;
        margin-block: 0;
        margin-inline: -5.5px;

        .custom_wave {
            width: 100%;
            position: relative;
            border-radius: 16px;
            overflow: hidden;

            .audio_button {
                position: absolute;
                left: 0;
                right: 0;
                margin-inline: auto;
                top: 25px;
            }

            .audio_player {
                position: absolute;
                left: 0;
                right: 0;
                margin-inline: auto;
                bottom: 25px;
            }

            .vjs-record .vjs-device-button.vjs-control,
            .vjs-record.video-js .vjs-control.vjs-record-indicator {
                display: none;
            }

            .video-js {
                background: var(--checkboxColor);
                padding: 0;
                height: 130px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .vjs-wavedisplay {
                    wave {
                        border-right: none !important;
                    }
                }
            }
        }

        .box_6 {
            width: 100%;
            padding-block: 0;
            padding-inline: 5.5px;
            position: relative;
        }

        .soundwave {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
            z-index: 0;
            // transform: translateY(-50%);
            display: flex;
            align-items: center;

            canvas {
                width: 100%;
                height: 150px;
            }

            img {
                width: 100% !important;
                height: 100%;
                cursor: pointer;
            }
        }

        .box_12 {
            width: 100%;
            padding-block: 0;
            padding-inline: 5.5px;
            position: relative;
        }

        .audio_button {
            text-align: center;
            z-index: 2;
            position: relative;

            // padding-top: 24px;
            svg.stopIcon {
                width: 52px;
                height: 52px;
                border-radius: 50%;
                color: var(--button_white_text);
                background: var(--chatred);
                cursor: pointer;
                padding: 6px;
            }

            img {
                width: 48px;
                height: 48px;
                margin-block: 0;
                margin-inline: auto;
                cursor: pointer;
            }

            svg {
                width: 52px;
                height: 52px;
                color: var(--chatred);
                cursor: pointer;
            }
        }

        .box_image {
            background: var(--checkboxColor);
            border-radius: 16px;
            width: 100%;
            height: 130px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;
            // overflow: hidden;
            isolation: isolate;
            text-transform: inherit;
            padding: 0;
            min-height: auto;
            min-width: auto;
            font-family: var(--poppin_stack);
            color: var(--activation_text);
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;

            .MuiTouchRipple-root {
                z-index: -1;

                .MuiTouchRipple-child {
                    background-color: var(--Ripplebg_color);
                }
            }

            &:hover {
                background: var(--box_btn_hover);
            }

            &.active {
                background: var(--active_bg);
            }

            .audio_player {
                position: absolute;
                top: 100px;

                span {
                    color: var(--activation_text_49454f);
                }
            }

            img {
                width: auto;
                cursor: pointer;
            }

            p {
                font-family: var(--poppin_stack);
                color: var(--activation_text);
                font-size: 14px;
                font-weight: 500;
                margin: 0;
                margin-block-start: 12px;
                z-index: 2;
                position: relative;
            }

            &.overflow_hidden {
                overflow: hidden;
            }
        }
    }
}

.audio-react-recorder__canvas {
    display: none !important;
}

.upload_buttons {
    width: 100%;
    margin-block-start: 6px;

    .upload_grid {
        display: flex;
        align-items: center;
        margin-block: 0;
        margin-inline: -5.5px;

        .box_6 {
            width: 50%;
            padding-block: 0;
            padding-inline: 5.5px;
        }

        .box_12 {
            width: 100%;
            padding-block: 0;
            padding-inline: 5.5px;
            position: relative;
        }

        .box_image {
            background: var(--checkboxColor);
            border-radius: 16px;
            width: 100%;
            height: 146px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;
            // overflow: hidden;
            isolation: isolate;
            text-transform: inherit;
            padding-block: 0;
            padding-inline: 10px;
            min-height: auto;
            min-width: auto;
            font-family: var(--poppin_stack);
            color: var(--activation_text);
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;

            .MuiTouchRipple-root {
                z-index: -1;

                .MuiTouchRipple-child {
                    background-color: var(--Ripplebg_color);
                }
            }

            label {
                cursor: pointer;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                margin: 0;
                padding: 0;
            }

            // .icon_box{
            //     width: 44px;
            //     height: 44px;
            //     margin: 0 auto;
            // }
            img {
                // width: 44px;
                height: 41px;
            }

            p {
                font-family: var(--poppin_stack);
                color: var(--activation_text);
                font-size: 14px;
                font-weight: 500;
                margin: 0;
                margin-block-start: 12px;
            }

            &:hover {
                background: var(--box_btn_hover);
            }

            &.active {
                background: var(--active_bg);
            }
        }
    }
}

// slidedrawing modal
.slider_Upload_modal {
    // &.mobile_drawing_modal {
    //     .MuiBox-root {
    //         width: 100%;
    //         border-radius: 0;
    //         max-width: 100%;
    //         height: 100%;
    //         padding-block: 22px;
    //         padding-inline: 20px;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;

    //         .close_modal {
    //             top: 10px;
    //             right: 10px;
    //             color: var(--textColor);
    //         }
    //     }

    //     .note_for_mobile {
    //         position: absolute;
    //         bottom: 0px;
    //         left: 0px;
    //         text-align: center;
    //         width: 100%;
    //         display: block;
    //         cursor: pointer;

    //         .sh_btn {
    //             flex-direction: column;

    //             button.MuiLoadingButton-loading span.btntext {
    //                 display: none;
    //             }
    //         }

    //         p {
    //             font-family: var(--poppin_stack);
    //             color: var(--parple);
    //             font-size: 14px;
    //             line-height: 20px;
    //             font-weight: 500;
    //             white-space: nowrap;

    //             &.discard {
    //                 color: var(--chatred);
    //                 margin-block-start: 2.5vh;
    //             }

    //             &.disabledbtn {
    //                 pointer-events: none;
    //                 color: var(--foot_gray);
    //             }

    //             &:hover {
    //                 text-decoration: underline;
    //             }
    //         }
    //     }

    //     hr,
    //     .sh_btn.images_crop {
    //         display: none;

    //         &.block {
    //             display: flex;
    //         }
    //     }

    //     .short_ans_head {
    //         display: none;
    //     }

    //     // .slide_upload_warp {
    //     //     display: flex;
    //     //     align-items: stretch;
    //     //     gap: 23px;
    //     //     // height: 100%;
    //     //     justify-content: center;
    //     //     width: max-content;
    //     //     margin-block: 0;
    //     //     margin-inline: auto;
    //     //     min-height: 260px;

    //     //     // overflow: auto;
    //     //     .slide_drawing_box {
    //     //         margin-block-end: 0;
    //     //     }
    //     // }

    //     .toolbar {
    //         align-items: flex-start;
    //         flex-direction: row;
    //         justify-content: flex-start;
    //         width: 100%;
    //         min-width: 97px;
    //         max-width: 97px;
    //         gap: 5px;
    //         position: relative;

    //         // height: 100%;
    //         .toolleft {
    //             padding-block: 0;

    //             ul {
    //                 li {
    //                     border-bottom: none;
    //                     // border-left: 1px solid transparent;

    //                     &:hover {
    //                         border-bottom: none;
    //                         // border-left: 1px solid var(--parple);
    //                     }

    //                     &.active {
    //                         border-bottom: none;
    //                         // border-left: 1px solid var(--parple);
    //                     }
    //                 }
    //             }
    //         }

    //         .toolright {
    //             ul {
    //                 li {
    //                     text-align: center;

    //                     .color {
    //                         margin-inline: auto;
    //                     }
    //                 }
    //             }
    //         }

    //         ul {
    //             display: block !important;
    //             min-width: 44px;

    //             li {
    //                 display: block !important;
    //             }
    //         }
    //     }
    // }

    .note_for_mobile {
        display: none;
    }

    // .MuiBox-root {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     width: 93%;
    //     // height: 100vh;
    //     max-width: max-content;
    //     border: none !important;
    //     outline: none !important;
    //     background: var(--background_white);
    //     border-radius: 24px;
    //     padding-block: 20px;
    //     padding-inline: 32px;

    //     .close_modal {
    //         position: absolute;
    //         top: 20px;
    //         right: 20px;
    //         z-index: 111;
    //         cursor: pointer;
    //         width: 30px;
    //         height: 30px;
    //         border-radius: 50%;
    //         background: var(--background_white);
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         opacity: 0.8;
    //         color: var(--textColor);

    //         &:hover {
    //             opacity: 1;
    //         }
    //     }
    // }

    .toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        gap: 0.5rem;
        min-height: 58px;

        .toolleft {
            width: auto;
            padding-block: 8px;
            padding-inline: 0px;

            ul {
                display: flex;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // border-bottom: 1px solid transparent;
                    padding-block: 6px;
                    padding-inline: 13px;
                    cursor: pointer;

                    svg {
                        &.shape {
                            path {
                                stroke: var(--activation_text);
                                transition: var(--transition);
                            }
                        }

                        path {
                            fill: var(--activation_text);
                            transition: var(--transition);
                        }
                    }

                    &:last-child {
                        border: none !important;

                        &:active {
                            background: linear-gradient(0deg, rgba(99, 120, 255, 0.15), rgba(99, 120, 255, 0.15)),
                                #ffffff;
                        }
                    }

                    &:hover {
                        // border-bottom: 1px solid var(--parple);
                        svg {
                            &.shape {
                                path {
                                    fill: var(--parple);
                                    stroke: var(--parple);
                                }
                            }

                            path {
                                fill: var(--parple);
                            }
                        }
                    }

                    &.active {
                        // border-bottom: 1px solid var(--parple);
                        svg {
                            &.shape {
                                path {
                                    fill: var(--parple);
                                    stroke: var(--parple);
                                }
                            }

                            path {
                                fill: var(--parple);
                            }
                        }
                    }

                    img {
                        cursor: pointer;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        .toolright {
            width: auto;
            padding: 0;

            ul {
                justify-content: space-between;
                align-items: center;
                display: flex;

                li {
                    display: inline-block;
                    padding: 6px;
                    cursor: pointer;

                    &.active {
                        svg {
                            path {
                                fill: var(--parple);
                            }

                            rect {
                                stroke: var(--parple);
                            }
                        }
                    }

                    .color {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        cursor: pointer;

                        &.sm-erasor {
                            width: 22px;
                            height: 22px;

                            &.active {
                                background: var(--activation_text) !important;
                            }
                        }

                        &.md-erasor {
                            width: 28px;
                            height: 28px;

                            &.active {
                                background: var(--activation_text) !important;
                            }
                        }

                        &.lg-erasor {
                            width: 32px;
                            height: 32px;

                            &.active {
                                background: var(--activation_text) !important;
                            }
                        }

                        &[style='background-color: rgb(255, 255, 255);'] {
                            border: 1px solid var(--parple);

                            svg {
                                color: var(--textblack);
                            }
                        }

                        &.active {
                            svg {
                                visibility: visible;
                            }
                        }

                        svg {
                            visibility: hidden;
                            font-size: 16px;
                            color: var(--textWhite);
                        }
                    }

                    img {
                        cursor: pointer;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }

    // .slide_upload_warp {
    //     width: 100%;

    //     .slide_drawing_box {
    //         width: fit-content;
    //         background: var(--background_white);
    //         border: 2px solid var(--border_gray);
    //         box-sizing: border-box;
    //         border-radius: 0px;
    //         margin-block-end: 8px;
    //         position: relative;
    //         overflow: hidden;
    //         margin-inline: auto;

    //         // #fabric-canvas-wrapper {
    //         //     border-radius: 12px;
    //         //     overflow: hidden;
    //         //     .canvas-container{
    //         //         border-radius: 12px;
    //         //     }
    //         // }
    //         // min-height: 350px;
    //         img {
    //             width: 100%;
    //             position: absolute;
    //             z-index: 0;
    //             top: 0;
    //             left: 0;
    //             touch-action: none;
    //             display: block;
    //             visibility: visible;
    //             border-radius: 12px;
    //         }

    //         .reactSketchCanvas {
    //             position: absolute !important;
    //             top: 0;
    //             left: 0;
    //         }

    //         .react_canvas {
    //             height: 350px;
    //         }
    //     }
    // }

    .MuiDivider-root {
        margin-block: 20px;
        border-color: var(--border_gray);
        border-width: 1px;
    }

    .short_ans_head {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 12px;

        img {
            filter: var(--filter_invert);
        }

        h4 {
            font-family: var(--poppin_stack);
            color: var(--textColor);
            font-size: 16px;
            font-weight: 500;
        }
    }

    .sh_btn {
        width: 100%;
        margin-block-start: 16px;
        text-align: center;

        &.images_crop {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;

            button {
                max-width: 240px;
                margin-block: 0 !important;
                // margin-inline: 16px !important;
                text-transform: none;

                @include max(767px) {
                    max-width: 175px;
                }

                &.cancel {
                    background: var(--cancelbtn);
                    color: var(--error_color);
                }
            }
        }

        button {
            @include activitybtn;
            margin-inline: 0;

            @include max(767px) {
                height: 45px;
            }
        }
    }
}

// video upload modal
.video_Upload_modal {
    .MuiBox-root {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 93%;
        // height: 100vh;
        max-width: 720px;
        border: none !important;
        outline: none !important;
        background: var(--background_white);
        border-radius: 24px;
        padding-block: 20px;
        padding-inline: 32px;

        .close_modal {
            position: absolute;
            top: 22px;
            right: 36px;
            z-index: 111;
            cursor: pointer;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: var(--background_white);
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.8;
            color: var(--textColor);

            &:hover {
                opacity: 1;
            }
        }
    }

    .video_box {
        width: 100%;

        .video_player {
            border-radius: 12px;
            overflow: hidden;
            background: var(--background_black);
        }
    }

    .video_upload_warp {
        width: 100%;

        .caption_img {
            flex: 1;
            width: 100%;
            margin-block-start: 20px;

            .error_sec {
                text-align: start;

                span {
                    text-align: start;
                }
            }

            .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: var(--parple);
            }

            .MuiFormHelperText-root.Mui-error {
                margin-inline-start: 0;
                letter-spacing: 0;
            }

            .MuiOutlinedInput-root {
                &.Mui-focused {
                    border-color: var(--parple);
                }

                border-radius: 8px;

                .MuiInputAdornment-root {
                    svg {
                        color: var(--parple);
                    }
                }

                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid var(--caption_border);
                }

                &.Mui-error {
                    .MuiOutlinedInput-notchedOutline {
                        border: 1px solid var(--error_color);
                    }
                }
            }

            input {
                padding-block: 12px;
                padding-inline: 14px;
                font-size: 14px;
                font-family: var(--poppin_stack);
                background: var(--input_background_white);
                border-radius: 8px;
                color: var(--input_color);
            }
        }

        // .video_container {
        // min-height: 374px;
        // }

        .error_sec {
            margin-block-start: 4px;

            span {
                text-align: center;
            }
        }
    }

    .audio_record {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .bttn {
            button {
                width: 40px;
                height: 40px;
                background: var(--caption_border);
                border-radius: 48px;

                svg {
                    font-size: 20px;
                    color: var(--textColor);
                }
            }
        }

        .audio_timer {
            span {
                display: inline-block;
                color: var(--textColor);
                font-family: var(--poppin_stack);
                line-height: 16px;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    .webcam_div {
        width: 100%;
        background: #000;
        overflow: hidden;
        border-radius: 12px;
        display: flex;
        position: relative;

        .noCAm {
            width: 100%;
            height: 24px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin-block: 0;
            margin-inline: auto;
            bottom: 0;
            text-align: center;

            p {
                font-family: var(--poppin_stack);
                line-height: 22px;
                font-size: 22px;
                font-weight: 500;
                color: var(--textWhite);
            }
        }

        .stopwatch-display {
            width: 100%;
            height: 33px;
            position: absolute;
            top: 16px;
            left: 0;
            right: 0;
            margin-block: 0;
            margin-inline: auto;
            bottom: 0;
            text-align: center;

            span {
                display: inline-block;
                background: rgb(217 99 103 / 75%);
                color: var(--textWhite);
                padding: 8px;
                font-family: var(--poppin_stack);
                line-height: 16px;
                font-size: 16px;
                font-weight: 500;
                border-radius: 8px;
                min-width: 96px;
            }
        }

        video {
            width: 100%;
            height: 350px;
        }
    }

    .video_capture_button {
        text-align: center;

        // padding-top: 24px;
        img {
            width: 48px;
            height: 48px;
            margin-block: 0;
            margin-inline: auto;
            cursor: pointer;
        }

        svg {
            width: 50px;
            height: 50px;
            color: var(--chatred);
            cursor: pointer;
        }
    }

    .MuiDivider-root {
        margin-block: 18px 24px;
        border-color: var(--border_gray);
        border-width: 1px;
    }

    .short_ans_head {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;

        img {
            filter: var(--filter_invert);
        }

        h4 {
            font-family: var(--poppin_stack);
            color: var(--textColor);
            font-size: 16px;
            font-weight: 500;
        }
    }

    .sh_btn {
        width: 100%;
        margin-block-start: 16px;
        text-align: center;

        &.images_crop {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;

            button {
                max-width: 240px;
                margin-block: 0 !important;
                margin-inline: 0px !important;
                text-transform: none;
            }
        }

        button {
            @include activitybtn;

            &.cancel {
                background: var(--cancelbtn);
                color: var(--error_color);
            }
        }
    }
}

// fill in blank
.text_area {
    width: 100%;

    .MuiFormControl-root {
        width: 100%;
        flex-direction: column-reverse;

        input {
            font-size: 15px;
            background: var(--input_background_white);
            border-radius: 12px;
            color: var(--input_color);
        }

        .MuiFormHelperText-root.Mui-error {
            margin-inline-start: 0;
        }

        .MuiOutlinedInput-root {
            border-radius: 12px;

            &.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: var(--parple);
            }
        }
    }

    label {
        color: #808494;
    }

    input::placeholder {
        color: var(--placeholder_color);
        opacity: 1;

        &.Mui-error {
            color: var(--error_color);
        }
    }

    label.Mui-focused {
        color: var(--parple);
    }

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid var(--border_gray);
    }
}

// images upload
.img_Upload_modal {
    .MuiBox-root {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 93%;
        // height: 100vh;
        max-width: 720px;
        border: none !important;
        outline: none !important;
        background: var(--background_white);
        border-radius: 24px;
        padding-block: 20px;
        padding-inline: 32px;

        .close_modal {
            position: absolute;
            top: 22px;
            right: 36px;
            z-index: 111;
            cursor: pointer;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: var(--background_white);
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.8;
            color: var(--textColor);

            &:hover {
                opacity: 1;
            }
        }
    }

    .MuiDivider-root {
        margin-block: 18px 24px;
        border-color: var(--border_gray);
        border-width: 1px;
    }

    .short_ans_head {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;

        img {
            filter: var(--filter_invert);
        }

        h4 {
            font-family: var(--poppin_stack);
            color: var(--textColor);
            font-size: 16px;
            font-weight: 500;
        }
    }

    .images_croper {
        width: 100%;

        &.cropNotAllow {
            :is(.images_croper_box, & > div, .cropper-drag-box, .cropper-wrap-box, .cropper-crop) {
                border-radius: 0 !important;
            }

            .images_croper_box {
                & > div {
                    background: none;
                }

                .cropper-view-box {
                    border: 0;
                }

                .cropper-face {
                    background-color: transparent;
                }
            }
        }

        .images_croper_box {
            border-radius: 12px;
            width: 100%;
            background: var(--background_000);

            // height: 320px;
            & > div {
                border-radius: 12px;
                width: 100%;
                background: var(--background_000);
                text-align: center;

                img {
                    // opacity: 1 !important;
                    object-fit: contain;
                    max-height: 320px;
                }

                .cropper-hidden {
                    display: none !important;
                }

                .cropper-crop {
                    border-radius: 12px;
                }
            }

            .cropper-drag-box {
                border-radius: 12px;
                background: var(--background_000);
            }

            .cropper-wrap-box {
                border-radius: 12px;
            }
        }
    }

    .cropping_panel {
        padding-block: 20px 0;
        padding-inline: 0px;
        display: flex;
        align-items: flex-start;
        height: 85px;
        gap: 10px;
    }

    .croping_icon {
        width: auto;
        width: auto;
        display: flex;
        align-items: center;
        // gap: 21px;
        justify-content: center;

        // padding-top: 10px;
        button {
            min-width: 48px;
            min-height: 44px;
            isolation: isolate;

            &:hover {
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)),
                    var(--background_white);
            }

            // &:active{
            //     background: var(--active_bg);
            // }
            .MuiTouchRipple-root {
                z-index: -1;

                .MuiTouchRipple-child {
                    background-color: var(--active_bg);
                }
            }
        }

        img {
            width: 24.78px;
            height: 21px;
            cursor: pointer;
            position: relative;
            z-index: 1;
            filter: var(--filter_invert);
        }
    }

    .caption_img {
        flex: 1;
        width: 100%;

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: var(--parple);
        }

        .MuiFormHelperText-root.Mui-error {
            margin-inline-start: 0;
            letter-spacing: 0;
        }

        .MuiOutlinedInput-root {
            &.Mui-focused {
                border-color: var(--parple);
            }

            border-radius: 8px;

            .MuiInputAdornment-root {
                svg {
                    color: var(--parple);
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: 1px solid var(--caption_border);
            }

            &.Mui-error {
                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid var(--error_color);
                }
            }
        }

        input {
            padding-block: 12px;
            padding-inline: 14px;
            font-size: 14px;
            font-family: var(--poppin_stack);
            background: var(--input_background_white);
            border-radius: 8px;
            color: var(--input_color);
        }
    }

    .sh_btn {
        width: 100%;
        margin-block-start: 16px;
        text-align: center;

        &.images_crop {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            gap: 30px;

            button {
                max-width: 240px;
                margin-block: 0;
                margin-inline: 0px;
                text-transform: none;

                &.cancel {
                    background: var(--cancelbtn);
                    color: var(--error_color);
                }
            }

            .MuiLoadingButton-startIconLoadingStart {
                display: none;
            }

            .MuiLoadingButton-loadingIndicator {
                position: relative;
                left: inherit;
                margin-inline-end: 10px;
            }
        }

        button {
            @include activitybtn;
        }
    }

    .img_upload_warp {
        width: 100%;
        // min-height: 460px;
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        justify-content: space-between;

        .searching_img {
            width: 100%;
            text-align: center;

            & > img {
                width: 200px;
                margin-block: 0;
                margin-inline: auto;
            }

            .cropping_panel {
                padding-block: 20px 0;
                padding-inline: 40px;
                margin-block-end: 0.5rem;
            }

            .searchimages_group {
                position: relative;
                width: 100%;
                max-width: 300px;
                margin-block: 0;
                margin-inline: auto;
                min-height: 344px;
                display: flex;
                align-items: center;
                justify-content: center;

                .search_error p {
                    size: 14px;
                    color: var(--footer-color);
                }

                .search_lotti {
                    position: absolute;
                    top: 38%;
                    right: 0;
                    width: 100%;
                    max-width: 170px;

                    .lottieContainer {
                        position: relative;

                        :is(.staticLottie, .dynamicLottie) {
                            position: absolute;
                            top: 38%;
                            right: 0;
                            width: 100%;
                            max-width: 170px;
                        }
                    }
                }
            }
        }

        .power_by {
            width: 100%;
            text-align: center;
        }
    }

    .images_list {
        width: 100%;

        .MuiImageList-standard {
            padding: 5px;
        }

        // .MuiImageListItem-standard {
        //     border-radius: 12px;
        //     cursor: pointer;

        //     &.active {
        //         border: 4px solid var(--parple);
        //         outline-offset: -2px;
        //     }

        //     &:hover {
        //         border: 4px solid var(--parple);
        //         outline-offset: -2px;
        //     }

        //     img {
        //         height: 130px;
        //         border-radius: 12px;
        //     }
        // }
    }

    .searchfield {
        width: 100%;
        max-width: 300px;
        margin-block: 0 18px;
        margin-inline: auto;

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: var(--parple);
        }

        .MuiOutlinedInput-root {
            &.Mui-focused {
                border-color: var(--parple);
            }

            padding: 0;
            padding-inline-end: 14px;
            border-radius: 8px;
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.08), rgba(99, 120, 255, 0.08)),
                var(--background_white);

            .MuiInputAdornment-root {
                svg {
                    color: var(--parple);
                    cursor: pointer;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: 1px solid transparent;
            }
        }

        input {
            padding-block: 7.5px;
            padding-inline: 14px;
            font-size: 14px;
            line-height: 14px;
            color: var(--input_color);

            &::placeholder {
                color: var(--placeholder_color);
            }
        }

        label {
            -webkit-transform: translate(14px, 11px) scale(1);
            -moz-transform: translate(14px, 11px) scale(1);
            -ms-transform: translate(14px, 11px) scale(1);
            transform: translate(14px, 11px) scale(1);
        }

        label.Mui-focused {
            -webkit-transform: translate(14px, -6px) scale(0.75);
            -moz-transform: translate(14px, -6px) scale(0.75);
            -ms-transform: translate(14px, -6px) scale(0.75);
            transform: translate(14px, -6px) scale(0.75);
        }

        .MuiInputLabel-shrink {
            -webkit-transform: translate(14px, -6px) scale(0.75);
            -moz-transform: translate(14px, -6px) scale(0.75);
            -ms-transform: translate(14px, -6px) scale(0.75);
            transform: translate(14px, -6px) scale(0.75);
        }
    }
}

// correct ans modal
.MuiModal-root {
    .correctAnsModal.MuiBox-root {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 91%;
        max-width: 440px;
        min-width: 270px;
        border: none !important;
        outline: none !important;
        background: var(--background_white);
        border-radius: 24px;
        padding-block: 8px !important;
        padding-inline: 33px !important;

        &.video_pre_modal {
            padding-block: 8px 20px !important;
            max-width: 360px;
        }

        &.pre_loader {
            max-width: 360px;
        }

        &.pdfmodal {
            padding: 25px !important;
            max-width: 600px;
            min-width: 270px;

            .close_modal {
                text-align: end;
                color: var(--textColor);

                svg {
                    font-size: 18px;
                    cursor: pointer;
                }
            }

            .instraction_head {
                padding: 0;

                .type_ans_box {
                    align-items: center;
                }
            }

            .foot_btn {
                padding: 0;
                padding-block-start: 8px;

                button {
                    max-width: 136px;
                    text-transform: none;
                }
            }

            span {
                text-align: start;
                font-family: var(--poppin_stack);
                color: var(--para_color);
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
            }
        }

        .instraction_head {
            width: 100%;
            padding-block: 28px 16px;
            padding-inline: 0px;

            .type_ans_box {
                width: 100%;
                display: flex;
                align-items: flex-start;
                gap: 1rem;

                .MuiAvatar-root {
                    width: 48px;
                    height: 48px;
                }

                img {
                    width: 48px;
                    height: 48px;
                    border-radius: 40px;
                }

                h5 {
                    text-align: start;
                    font-family: var(--poppin_stack);
                    color: var(--para_color);
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 22px;
                }

                .chat_text {
                    flex: 1;

                    h4 {
                        text-align: start;
                        font-family: var(--poppin_stack);
                        color: var(--para_color);
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
            }
        }

        .multiplecorrectAnsModal_content {
            width: 100%;
            max-width: 248px;
            margin-block: 0;
            margin-inline: auto;
            padding-block: 12px;

            ul {
                li {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: center;
                    gap: 10px;
                    background: var(--checkboxColor);
                    height: 40px;
                    border-radius: 28px;
                    margin-block-start: 8px;

                    &:first-child {
                        margin-block-start: 0;

                        p {
                            color: var(--checkboxGreen);
                        }
                    }

                    &:nth-of-type(2) {
                        p {
                            color: var(--checkboxPink);
                        }
                    }

                    &:nth-of-type(3) {
                        p {
                            color: var(--checkboxBlue);
                        }
                    }

                    &:nth-of-type(4) {
                        p {
                            color: var(--checkboxYellow);
                        }
                    }

                    &:nth-of-type(5) {
                        p {
                            color: var(--checkboxPurple);
                        }
                    }

                    &:nth-of-type(6) {
                        p {
                            color: var(--checkboxDeepGreen);
                        }
                    }

                    p {
                        font-weight: 600;
                        font-size: 18px;
                        font-family: var(--poppin_stack);
                        color: var(--activation_text);
                        line-height: 24px;
                    }
                }
            }
        }

        .correctAnsContent {
            width: 100%;
            max-width: 270px;
            margin-block: 0;
            margin-inline: auto;
            padding-block: 14px;
            padding-inline: 16px 0;

            ul {
                li {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-block-start: 10px;

                    &:first-child {
                        margin-block-start: 0;
                    }

                    .corectbox {
                        width: 30px;
                        height: 24px;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: var(--textWhite);
                        padding: 6px;

                        svg {
                            display: flex;
                            font-size: 18px;
                        }

                        &.right {
                            background: var(--sucess);
                        }

                        &.wrong {
                            background: var(--chatred);
                        }
                    }

                    p {
                        font-weight: 500;
                        font-size: 16px;
                        font-family: var(--poppin_stack);
                        color: var(--activation_text);
                    }
                }
            }
        }

        .foot_btn {
            width: 100%;
            text-align: center;
            padding-block: 16px 28px;

            button {
                @include activitybtn;
                max-width: 120px;
            }
        }
    }
}

.error_modal {
    .inner_box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
        // height: 100vh;
        max-width: 555px;
        border: none !important;
        outline: none !important;
        background: var(--background_white);
        border-radius: 24px;
        padding-block: 20px;
        padding-inline: 54px;

        .media {
            display: flex;
            align-items: center;
            justify-content: center;

            &.error_media {
                padding-block: 30px;
            }

            .media-icon {
                margin-inline-end: 15px;

                svg {
                    color: #ec3543;
                    font-size: 45px;
                }
            }

            .media_content {
                // flex: 1;

                p {
                    font-family: var(--poppin_stack);
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 500;
                    color: var(--activation_text);
                }
            }
        }
    }
}

// slide show_modal
.MuiModal-root .slide_show_modal.MuiBox-root {
    position: absolute;
    top: 0%;
    left: 0%;
    bottom: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    max-width: var(--lightBoxMax-width);
    border: none !important;
    outline: none !important;
    background: transparent;
    border-radius: 24px;
    padding: 24px !important;

    .slide_show_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 24px;
        overflow: hidden;

        img {
            width: 100%;
            max-height: var(--lightBoxMax-height);
            min-height: var(--lightBoxMin-height);
            object-fit: contain;
            background: var(--background_black);
            border-start-start-radius: 24px;
            border-start-end-radius: 24px;

            @include max(900px) {
                max-height: var(--lightBoxMax-height85);
            }
        }
    }

    .loader_main {
        width: 100%;

        .MuiLinearProgress-root {
            height: 6px;
            background: var(--progrss_gray);
            border-radius: 40px;

            .MuiLinearProgress-bar {
                background: var(--parple);
                border-radius: 40px;
            }
        }
    }
}

// video_pre_upload_modal

.video_pre_upload_modal {
    padding-block-start: 22px;

    .percent {
        position: relative;
        display: grid;
        place-items: center;

        svg {
            position: relative;
            width: 142px;
            height: 142px;
            transform: rotate(-90deg);
        }

        svg circle {
            width: 100%;
            height: 100%;
            fill: none;
            stroke: var(--light_color);
            stroke-width: 12;
            stroke-linecap: round;
        }

        svg circle:last-of-type {
            stroke-dasharray: 400px;
            stroke-dashoffset: calc(400px - (400px * var(--percent)) / 100);
            stroke: var(--color);
        }

        .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            h4 {
                font-family: var(--poppin_stack);
                font-size: 22px;
                line-height: 27px;
                font-weight: normal;
                font-weight: 500;
                color: var(--activation_text);
                text-align: center;

                span {
                    display: block;
                    font-family: var(--poppin_stack);
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: normal;
                    font-weight: 400;
                    color: var(--activation_text);
                    margin-block-start: 4px;
                }
            }
        }
    }

    .video_loading {
        width: 100%;
        padding-block-end: 12px;
    }

    .foot_btn {
        padding-top: 24px !important;
        padding-block-end: 0 !important;
    }

    h4 {
        font-family: var(--poppin_stack);
        color: var(--activation_text);
        font-size: 18px;
        font-weight: 500;
        text-align: start;
        line-height: 22px;
    }
}

.MuiModal-root .MuiBackdrop-root {
    background-color: var(--backDrop_bg);
}

// images lightbox_modal
.lightbox_modal {
    .modal_main_container {
        position: absolute;
        top: 0%;
        left: 0%;
        right: 0;
        bottom: 0;
        margin: auto;
        transform: none;
        width: 100%;
        height: fit-content;
        max-width: var(--lightBoxMax-weight);
        // min-width: 400px;
        padding-block: 0px;
        padding-inline: 20px;
        outline: none;
        box-shadow: none;
        border: none;

        &.video_lightbox {
            .image_light_box {
                .big__content {
                    max-height: 70vh;
                    min-height: 200px;
                }
            }
        }
    }

    .image_light_box {
        border-radius: 1vw;
        margin-inline: auto;
        margin-block: 0;
        position: relative;
        background: none;

        .big__content {
            text-align: center;
            padding: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            max-height: var(--lightBoxMax-height);
            min-height: 200px;
            border-radius: 1vw;
            position: relative;

            &.video_background {
                background: var(--background_black);
                border-radius: 1vw;

                .video_player {
                    // width: 100% !important;
                    // height: 100% !important;
                    display: flex;
                    max-height: var(--lightBoxMax-height);
                    min-height: 200px;
                    border-radius: 12px;
                    overflow: hidden;

                    video {
                        height: auto !important;
                    }
                }
            }

            img {
                width: 100%;
                max-height: var(--lightBoxMax-height);
                min-height: 200px;
                object-fit: contain;
                background: var(--background_black);
                border-radius: 12px 12px;
            }

            .images_content {
                width: 100%;
                position: absolute;
                bottom: 0px;
                z-index: 1;
                padding-block: 14px;
                padding-inline: 16px;
                background: rgba(0, 0, 0, 0.7);
                border-radius: 0 0 12px 12px;

                p {
                    font-family: var(--mali);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    color: var(--textWhite);
                    word-break: break-word;
                }
            }
        }
    }
}

// language modal
.language_modal {
    .language_modal_container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 604px;
        padding: 20px;
        outline: none;
        box-shadow: none;
        border: none;
    }

    .close_modal {
        position: relative;
        // top: 22px;
        // right: 40px;
        z-index: 111;
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.8;
        margin-inline-start: auto;
        color: var(--textColor);

        &:hover {
            opacity: 1;
        }
    }

    .language_wrapper {
        width: 100%;
        background-color: var(--background_white);
        border-radius: 24px;
        padding-block: 16px 20px;
        padding-inline: 32px;

        .title {
            width: 100%;

            h4 {
                font-family: var(--poppin_stack);
                color: var(--activation_text);
                font-size: 16px;
                font-weight: 500;
                line-height: 21px;
            }
        }

        .current_language_box {
            width: 100%;
            background: var(--parple_08);
            border-radius: 12px;
            margin-block-start: 20px;
            padding-block: 14px;
            padding-inline: 28px;

            p {
                font-family: var(--poppin_stack);
                color: var(--activation_text);
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;

                span {
                    font-family: var(--poppin_stack);
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 19px;
                    color: var(--parple);
                }
            }
        }

        .language_btn_sec {
            width: 100%;
            overflow: auto;
            max-height: 50vh;
        }

        .language_wrap {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px 4px;
            flex-wrap: wrap;
            padding-block: 21px;

            .language_box {
                width: 32.7%;
                min-width: auto;
                min-height: auto;
                padding-block: 6px;
                padding-inline: 12px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-radius: 8px;
                border: 1px solid transparent;
                isolation: isolate;
                min-height: 32px;

                .language_name {
                    flex: 1;
                    @include displayFlex(row, center, flex-start);
                    gap: 8px;

                    img {
                        width: 20px;
                    }

                    svg {
                        display: flex;
                        font-size: 20px;
                    }

                    h4 {
                        font-family: var(--poppin_stack);
                        color: var(--activation_text);
                        font-size: 12px;
                        line-height: 15px;
                        font-weight: 400;
                        text-align: start;
                        text-transform: none;
                    }
                }

                &:hover {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)),
                        var(--background_white);
                }

                .MuiTouchRipple-root {
                    z-index: 0;

                    .MuiTouchRipple-child {
                        background: rgba(99, 120, 255, 0.2);
                    }
                }

                &.active {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)),
                        var(--background_white);
                    border: 1px solid var(--parple);
                }
            }
        }
    }

    // &.mobile_language_modal {
    //     .close_modal {
    //         position: absolute;
    //         top: 16px;
    //         right: 16px;
    //     }
    //     .language_modal_container {
    //         padding: 0 !important;
    //         height: 100vh;
    //         .language_wrapper {
    //             border-radius: 0;
    //             height: 100%;
    //             padding: 20px 32px;
    //             display: flex;
    //             flex-direction: column;
    //             justify-content: space-between;
    //             .title {
    //                 width: fit-content;
    //                 margin-top: 6px;
    //                 display: flex;
    //                 align-items: center;
    //                 gap: 8px;
    //                 line-height: 20px;
    //                 min-height: 21px;
    //                 cursor: pointer;
    //                 svg {
    //                     font-size: 16px;
    //                     color: var(--activation_text);
    //                     display: flex;
    //                 }
    //             }
    //             .current_language_box {
    //                 margin-top: 26px;
    //                 display: flex;
    //                 align-items: center;
    //                 justify-content: space-between;
    //                 padding-inline-end: 14px;
    //                 svg {
    //                     font-size: 16px;
    //                     color: var(--activation_text);
    //                     display: flex;
    //                     cursor: pointer;
    //                 }
    //             }
    //         }
    //     }
    // }
}

.mb_User_badge {
    display: none !important;
}

.jodit-toolbar-button.jodit-ui-group__brush {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: transparent;
    border: 8px solid var(--textWhite);
    cursor: pointer;

    & button {
        transform: scale(50);
    }
}

.short_ans_form .ql-toolbar.ql-snow {
    border: 2px solid var(--border_gray);
    border-radius: 0px 0px 12px 12px;
    background: var(--background_white_no_change);
}

.quill {
    display: flex;
    flex-direction: column-reverse;
    border: 2px solid var(--border_gray);
    border-bottom: none;
    border-radius: 12px 12px 0 0;
    background: var(--background_white_no_change);

    .ql-container.ql-snow {
        height: 120px;
        border: 0;

        .ql-editor {
            &.ql-blank::before {
                font-size: 15px;
                font-weight: 400;
                color: var(--quillPlaceholder);
                font-style: normal;
            }

            p {
                font-size: 15px;
                font-weight: 400;
                font-family: var(--mali);

                * {
                    font-family: var(--mali);
                }
            }

            :is(ol, ul) {
                padding: 0;
                font-family: var(--mali);
            }

            li {
                font-size: 15px;
                font-weight: 400;
                font-family: var(--mali);

                &::before {
                    width: auto;
                }
            }
        }
    }

    .ql-toolbar.ql-snow {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 48px;
        padding-block: 0px;
        padding-inline: 19px;
        border: 0;
        border-top: 1px solid var(--border_gray) !important;

        span.ql-formats {
            margin: 0;

            button {
                height: 22px;
                width: auto;
                padding: 0;
                margin-inline-end: 25px;
            }

            .ql-color {
                width: 20px;
                height: 20px;

                &[value='black'] {
                    background: var(--background_black);
                }

                // .ql-picker-label {
                //     padding: 0;
                //     margin: 1px 0 0 -6px;
                //     border: 0;
                //     border-radius: 50%;
                // }

                // &.ql-expanded {
                //     .ql-picker-options {
                //         top: -150%;
                //         left: -20%;

                //         display: flex;
                //         align-items: center;
                //         justify-content: center;
                //         gap: 7px;

                //         width: fit-content;
                //         padding: 5px 7px;
                //         border-radius: 3px;

                //         span {
                //             border-radius: 50%;
                //         }
                //     }
                // }
            }
        }
    }
}

.ql-snow.ql-toolbar .ql-formats:nth-of-type(1) button:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-formats:nth-of-type(1) button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-formats:nth-of-type(1) button.ql-active {
    stroke: var(--parple) !important;
}

span.ql-formats:nth-of-type(2) {
    margin: 0 !important;

    button.ql-active {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0.5px;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: url(../../../../../public/assets/logo/done.svg) no-repeat center;
            background-size: 10px;
        }
    }

    .done {
        display: none;
    }

    .ql-color {
        width: 25px;
        padding-block: 3px;
        padding-inline: 4px;

        &:hover {
            .ql-stroke {
                color: transparent;
            }
        }

        svg {
            width: 18px;
            height: 18px;
            border-radius: 50%;
        }

        &[value='black'] {
            svg,
            .ql-stroke {
                stroke: var(--textblack);
                background: var(--background_000);
            }
        }

        &[value='red'] {
            svg,
            .ql-stroke {
                stroke: red;
                background: red;
            }
        }

        &[value='blue'] {
            svg,
            .ql-stroke {
                stroke: blue;
                background: blue;
            }
        }

        &[value='green'] {
            svg,
            .ql-stroke {
                stroke: green;
                background: green;
            }
        }

        &[value='purple'] {
            svg,
            .ql-stroke {
                stroke: purple;
                background: purple;
            }
        }
    }
}

.ql-snow.ql-toolbar .ql-formats:nth-of-type(2) button:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-formats:nth-of-type(2) button.ql-active .ql-stroke {
    stroke: transparent !important;
}

// responsive
// @media (max-width: 1500px) {
// .slider_Upload_modal .MuiBox-root {
// #fabric-canvas-wrapper {
//     max-height: 500px !important;
// }
// max-width: 1150px;
// }
// }

@media (max-width: 1200px) {
    .main_body .left_card .presenter_foot {
        padding: 20px;
    }

    .quill .ql-toolbar.ql-snow .ql-formats {
        button {
            height: 20px;
            margin-inline-end: 20px;
        }

        .ql-color.ql-picker.ql-color-picker {
            width: 18px;
            height: 18px;
        }
    }

    // .slider_Upload_modal .MuiBox-root {
    // #fabric-canvas-wrapper {
    //     max-height: 400px !important;
    // }
    // max-width: 1000px;
    // }
}

@media (max-width: 1100px) {
    .main_body .right_card .ctc_badge {
        width: 100%;
    }

    // .main_body .left_card .presenter_foot .footleft {
    // width: 40px;
    // }

    .clasroom_header.MuiPaper-root .container {
        padding-block: 0px;
        padding-inline: 32px;
    }
}

@media (min-width: 600px) and (max-width: 999px) {
    .main_body .container {
        // max-width: 520px;
        padding-block: 0;
        padding-inline: 32px;
    }
}

@media (max-width: 900px) {
    .MuiGrid-grid-md-8,
    .MuiGrid-grid-md-4,
    .MuiGrid-grid-md-1,
    .MuiGrid-grid-md-2,
    .MuiGrid-grid-md-3,
    .MuiGrid-grid-md-5,
    .MuiGrid-grid-md-6,
    .MuiGrid-grid-md-7,
    .MuiGrid-grid-md-9,
    .MuiGrid-grid-md-10,
    .MuiGrid-grid-md-11,
    .MuiGrid-grid-md-12 {
        -webkit-flex-basis: 100% !important;
        -ms-flex-preferred-size: 100% !important;
        flex-basis: 100% !important;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        max-width: 100% !important;
    }

    .lightbox_modal .modal_main_container {
        max-width: 80vw;

        .image_light_box {
            .big__content {
                max-height: 80vh;

                img {
                    max-height: 80vh;
                }
            }
        }
    }

    .quill .ql-toolbar.ql-snow .ql-formats {
        button {
            height: 18px;
            margin-inline-end: 15px;
        }

        .ql-color.ql-picker.ql-color-picker {
            width: 16px;
            height: 16px;
        }
    }

    .clasroom_header.MuiPaper-root .container {
        padding-block: 0px;
        padding-inline: 32px;
    }

    .chatbox .big_avatar img,
    .chatbox .big_avatar .MuiAvatar-root {
        width: 60px;
        height: 60px;
    }

    .main_body .left_card .presenter_foot {
        padding-block: 14px;
        padding-inline: 16px;
    }

    .main_body {
        .left_card {
            .presenter_foot {
                .responce_icon {
                    flex: inherit;
                    text-align: start;
                }

                .footright {
                    // padding: 7px;
                    ul {
                        li {
                            svg {
                                font-size: 23px;
                            }
                        }
                    }
                }
            }
        }

        .right_card {
            &.basicUser {
                display: flex;
                align-items: center;
                gap: 6px;

                .rc_profile {
                    flex: 1;
                }

                .level_point_sec {
                    margin-block-start: 0;

                    .box {
                        min-width: 166px;
                    }
                }
            }

            .rc_profile {
                flex-direction: row;

                .profilename {
                    text-align: start;
                }

                .profilename {
                    .pro_user_profile_data {
                        .Pro_user_content {
                            .current_level {
                                text-align: end;
                                justify-content: flex-end;
                            }
                        }
                    }
                }
            }

            .level_point_sec {
                flex-direction: row;
                gap: 11px;

                .box {
                    min-height: 60px;
                    flex-wrap: wrap;
                    padding-inline: 12px;
                }
            }

            .ctc_badge {
                width: 280px;
            }
        }
    }

    // .main_body {
    //     padding-block-end: 60px;
    //     height: auto;
    // }

    .clasroom_header.MuiPaper-root .header_logo span {
        display: none;
    }

    .clasroom_header.MuiPaper-root .user_badge p {
        display: none;
    }

    .clasroom_header.MuiPaper-root .user_badge p + svg {
        display: block;
    }

    .clasroom_header.MuiPaper-root .header_classcode .codenumber span {
        width: 28px;
        height: 30px;
        font-size: 18px;
    }

    .clasroom_header.MuiPaper-root .header_classcode {
        gap: 0.6rem;
    }

    .main_body .left_card .presenter_foot .responce_icon ul li .hand {
        background: none;
        padding-block: 0;
        border-left: 1px solid var(--caption_border);
        border-radius: 0;
    }

    .main_body .left_card .presenter_foot .responce_icon ul li .hand span {
        display: none;
    }

    .answer_area textarea {
        padding: 20px;
        height: 220px !important;
    }

    .multiple_choice .custom_sheck {
        gap: 0.8rem;
    }

    .toolate {
        svg {
            width: 50% !important;
        }
    }

    .slider_Upload_modal .toolbar .toolleft ul li {
        padding-inline: 12px;
    }
}

@media (max-width: 600px) {
    .main_body .left_card .presenter_foot .responce_icon ul li img {
        width: 30px;
    }

    .main_body .left_card .presenter_foot {
        padding-block: 12px;
        padding-inline: 16px;
    }

    // .main_body .left_card .presenter_foot .footright {
    //     padding: 0;
    //     width: 74px;
    //     border: none;
    // }

    .main_body .left_card .presenter_foot .responce_icon ul li {
        margin-block: 0;
        margin-inline: 5px;
    }

    .answer_area textarea {
        padding: 20px;
        height: 160px !important;
    }

    .MuiModal-root .MuiBox-root {
        padding: 20px !important;
    }

    .lightbox_modal .modal_main_container {
        padding: 0 20px !important;
    }

    .sh_btn,
    .slider_Upload_modal .sh_btn.images_crop,
    .img_Upload_modal .sh_btn.images_crop,
    .video_Upload_modal .sh_btn.images_crop {
        gap: 10px;
    }

    .sh_btn.images_crop button {
        margin-block: 0 !important;
    }

    .slider_Upload_modal .sh_btn.images_crop button {
        margin-block: 0 !important;
    }

    .video_Upload_modal .sh_btn.images_crop button {
        margin-block: 0 !important;
    }

    .main_body .left_card .presenter_text h6,
    .main_body .left_card .presenter_img.audienceimage h6,
    .main_body .left_card .presenter_img.loading_page .text h6 {
        font-size: 18px;
    }

    .language_modal .language_wrapper .language_wrap {
        gap: 8px 1%;

        .language_box {
            width: 49%;
        }
    }
}

@media (max-width: 599px) {
    .main_body .container {
        padding-block: 0px;
        padding-inline: 16px;
        max-width: 100%;
    }

    .clasroom_header.MuiPaper-root .container {
        padding-block: 0px;
        padding-inline: 16px;
    }

    .lightbox_modal .modal_main_container {
        max-width: 100%;
    }

    .main_body {
        .right_card {
            .level_point_sec {
                .box {
                    .text {
                        p {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 530px) {
    .img_Upload_modal .sh_btn.images_crop button {
        margin-block: 0;
    }

    .sh_btn,
    .slider_Upload_modal .sh_btn.images_crop,
    .img_Upload_modal .sh_btn.images_crop,
    .video_Upload_modal .sh_btn.images_crop {
        gap: 6px;
    }

    .main_body .left_card .presenter_foot .footleft .pfoot_profile {
        gap: 16px;
    }

    .main_body .left_card .presenter_foot .footleft .pfoot_profile .MuiAvatar-root {
        width: 45px;
        height: 45px;
    }

    .main_body .left_card .presenter_foot .footleft .pfoot_profile .pfoot_profile_content h4 {
        font-size: 14px;
        margin-block-start: 0px;

        @media (min-width: 350px) {
            white-space: nowrap;
        }
    }

    .main_body .left_card .presenter_foot .footleft .pfoot_profile .pfoot_profile_content .presenterIsCCT img {
        height: 22px;
    }

    .main_body .left_card .presenter_foot .footleft .pfoot_profile .pfoot_profile_content p {
        font-size: 12px;
        margin-block-start: 3px;
    }

    .MuiModal-root .slide_show_modal.MuiBox-root.mobile_rotate {
        border-radius: 0;
        padding: 0 !important;

        .slide_show_wrapper {
            border-radius: 0;
            // transform: rotate(90deg);
            // overflow: none;
        }

        img {
            border-start-start-radius: 0px;
            border-start-end-radius: 0px;
            max-height: calc(100vh - 6px);
        }
    }
}

@media (max-width: 580px) {
    .slider_Upload_modal .toolbar {
        // flex-direction: column;
        justify-content: center;
        gap: 0;
    }
}

@media (max-width: 530px) {
    // header
    .header_logo,
    .user_badge {
        padding-block: 0.9rem;
        padding-inline: 10px;
    }

    // .main_body {
    //     padding-block-start: 80px;
    // }

    .main_body .left_card .presenter_foot .responce_icon ul li img {
        width: 28px;
    }

    .main_body .left_card .presenter_foot {
        padding-block: 10px;
        padding-inline: 16px;
    }

    .iconbtn_sec .icon_btn svg {
        font-size: 14px;
    }

    .main_body .left_card .presenter_foot .responce_icon ul li {
        display: none;
    }

    .main_body .left_card .presenter_foot .responce_icon ul li:first-child {
        display: inline-block;
    }

    .main_body .left_card .presenter_foot .responce_icon ul li:last-child {
        display: inline-block;
    }

    .clasroom_header.MuiPaper-root .header_classcode .codenumber span {
        background: none;
        width: auto;
        margin: 0;
    }

    .clasroom_header.MuiPaper-root .container {
        padding-block: 0px;
        padding-inline: 16px;
        min-width: 280px;
    }

    .error_modal {
        .inner_box {
            width: 93%;
        }
    }

    // .answer_area {
    //     // margin-top: 36px;
    // }
    // .main_body .left_card .presenter_img img.notslideimage {
    //     // height: 100px;
    // }
    // .main_body .short_answer {
    //     // min-height: 388px;
    // }
    // .main_body .short_answer .type_ans_warp {
    //     // min-height: 285px;
    // }
    .main_body .left_card .presenter_text h6,
    .main_body .left_card .presenter_img.audienceimage h6,
    .main_body .left_card .presenter_img.loading_page .text h6 {
        font-size: 16px;
    }

    .video_Upload_modal .sh_btn.images_crop button {
        margin-block: 0 !important;
        // margin-inline: 5px !important;
    }

    .MuiModal-root .correctAnsModal.MuiBox-root {
        padding-block: 0px !important;
        padding-inline: 20px !important;
    }

    .MuiModal-root .correctAnsModal.MuiBox-root .correctAnsContent {
        padding-inline-start: 0;
    }

    .sh_btn button.MuiLoadingButton-loading span.btntext {
        display: none;
    }
}

@media (max-width: 480px) {
    .language_modal .language_wrapper {
        padding-block: 16px 20px;
        padding-inline: 16px;
    }

    .main_body {
        .right_card {
            &.basicUser {
                .level_point_sec {
                    .box {
                        min-width: 92px;
                    }
                }
            }

            .level_point_sec {
                .box {
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: center;
                    min-height: 82px;
                }
            }

            .ctc_badge {
                width: 280px;
            }
        }

        .left_card {
            .presenter_foot {
                .footright {
                    padding-block: 9px;
                    width: max-content;
                    border: none;

                    ul {
                        li {
                            padding-inline: 10px;

                            svg {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .language_modal .language_wrapper .current_language_box {
        padding: 14px;
    }

    .box_align > :is(.logo_box, .hambar_box) {
        flex: inherit;
    }
}

@media (max-width: 360px) {
    .main_body {
        .right_card {
            .level_point_sec {
                .box {
                    .text {
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 330px) {
    .clasroom_header.MuiPaper-root .header_classcode {
        gap: 5px;
    }

    .main_body {
        .right_card {
            &.basicUser {
                display: block;

                .level_point_sec {
                    margin-block-start: 28px;

                    .box {
                        min-width: 92px;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }
                }
            }

            .level_point_sec {
                margin-block-start: 28px;

                .box {
                    min-width: 92px;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
            }

            .level_point_sec {
                flex-direction: column;
            }
        }

        .left_card {
            .presenter_foot {
                .footright {
                    ul {
                        li {
                            svg {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }

    .main_body .right_card .level_point_sec .box {
        width: 100%;
        min-height: 62px;
    }

    .slider_Upload_modal .toolbar .toolleft ul li {
        padding-inline: 8px;
    }

    .slider_Upload_modal .toolbar .toolright ul li {
        padding: 5px;
    }
}

.ql-formats .ql-color-picker {
    position: relative;

    .ql-picker-label svg {
        position: relative;
        border-radius: 50%;

        :is(line:nth-last-of-type(1), polyline) {
            display: none;
        }

        line:nth-of-type(1) {
            position: absolute;

            stroke-width: 50px;
            opacity: 1;
            transform: scale(1);
        }
    }

    .ql-picker-options {
        position: absolute;
        top: -33px !important;
        left: 0;

        width: 122px !important;
        padding: 5px !important;

        span {
            border-radius: 50%;
            margin: 0 3px;
            margin-block: 0;
            margin-inline: 3px;
        }
    }
}

.short_modalinner.slideDrawingContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    overflow: auto;
    background: var(--background_white);

    &.MuiBox-root {
        padding: 0 !important;
    }

    .slide_upload_warp {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: max-content;
        height: max-content;
        padding-block: 20px;
        padding-inline: 20px;

        @include max(767px) {
            padding-block: 15px;
            padding-inline: 15px;
        }

        &.heightFull {
            width: 100%;
            height: 100%;
        }

        &.mobileView {
            flex-direction: row;
            align-items: flex-start;

            .toolsWrapper {
                width: 140px;
                padding-inline-start: 20px;

                .toolbar {
                    align-items: flex-start;
                    gap: 10px;

                    width: max-content;
                    height: max-content;
                    padding-block: 0;

                    @include max(767px) {
                        gap: 5px;
                    }

                    :is(.toolleft, .toolright) {
                        width: 45px;
                        padding-block: 0;

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            row-gap: 10px;
                        }
                    }
                }

                .sh_btn {
                    flex-direction: column-reverse;
                    gap: 10px;

                    margin-block-start: auto;
                    padding-block-start: 30px;

                    @include max(767px) {
                        gap: 10px;
                    }

                    button {
                        max-width: 200px;
                        height: 45px;

                        @include max(767px) {
                            height: 40px;
                        }
                    }
                }
            }
        }

        .toolsWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 100%;

            .toolbar {
                justify-content: center;
                gap: 50px;
                flex-wrap: nowrap;

                padding-block-start: 10px;

                @include max(580px) {
                    gap: 0;
                }

                &.pcMode {
                    @include max(575px) {
                        flex-direction: column;
                    }
                }
            }

            .sh_btn {
                margin-block-start: 0;
                padding-block-start: 50px;

                &.smallButtons {
                    flex-direction: row !important;
                    gap: 10px !important;

                    padding-block-start: 20px !important;

                    .btnText {
                        display: none;
                    }

                    button {
                        width: 40px !important;
                        min-width: 40px;
                        height: 40px !important;
                    }
                }

                &:not(.smallButtons) {
                    .smallScreenIcon {
                        display: none;
                    }
                }

                @include max(767px) {
                    padding-block-start: 30px;
                }

                button {
                    @include max(400px) {
                        max-width: 130px;
                    }
                }
            }
        }

        .closeIconWrapper {
            position: absolute;
            inset: 5px 5px auto auto;

            padding: 0;

            .closeIcon {
                color: var(--icon_black);
                font-size: 25px;
            }
        }

        .slide_drawing_box {
            display: flex;
            align-items: center;
            justify-content: center;

            width: max-content;
            height: max-content;

            &.heightFull {
                padding-block-end: 10px;
            }

            .main_div {
                display: flex;
                align-items: center;
                justify-content: center;

                width: max-content;
                height: max-content;

                .fabric-canvas-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    border: 2px solid var(--border_gray);
                    border-radius: 12px;

                    @include max(767px) {
                        border-radius: 6px;
                    }

                    > .canvas-container {
                        width: 100% !important;
                        height: 100% !important;

                        > canvas {
                            width: 100% !important;
                            height: 100% !important;
                            border-radius: 12px;

                            @include max(767px) {
                                border-radius: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}
