@use '../../../../../../../Style.scss' as *;

.modal_qna {
    .modal_content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 95%;
        max-width: 640px;
        background: var(--background_white);
        border: none !important;
        outline: none !important;
        border-radius: 12px;
    }

    :global(.MuiBackdrop-root) {
        background-color: var(--backDrop_bg);
    }

    .modal_body {
        width: 100%;
        // padding-inline-end: 7px;
        overflow: hidden;

        .question_list {
            width: 100%;
            max-height: calc(100vh - 340px);
            padding-inline: 32px;
            overflow: auto;

            @include max(575px) {
                padding-inline: 15px 15px;
            }
        }

        .btn_group {
            width: 100%;
            @include displayFlex(row, center, center);
            margin-block: 18px;

            .prime_btn {
                @include prime;
            }
        }
    }
}

.modal_content:global(.MuiBox-root) {
    @include max(600px) {
        padding: 0 !important;
    }
}
