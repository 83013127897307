@import '../../../Style.scss';

html,
body {
    height: 100%;
    position: relative;
}

body {
    #root {
        height: 100%;
    }

    .App {
        height: 100%;
    }

    .classpooint_joining {
        height: 100%;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.classpooint_joining {
    width: 100%;
    // height: 100vh;
    // background: url(../../../../public/assets/images/joinbg.jpg) no-repeat bottom center yellow;
    background-size: cover;
    background-position: bottom center;
    -webkit-transition: background-image 0.8s ease-in-out;
    -o-transition: background-image 0.8s ease-in-out;
    -moz-transition: background-image 0.8s ease-in-out;
    transition: background-image 0.8s ease-in-out;

    display: flex;
    align-items: center;
    justify-content: center;

    .h_100 {
        height: 100%;
    }

    .join_card {
        width: 400px;
        height: 580px;
        margin-block: 0;
        margin-inline: auto;
        background: var(--background_white);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        border-radius: 24px;

        .join_card_wrap {
            padding-block-start: 72px;
            position: relative;
            height: 100%;
            text-align: center;

            .sign_logo {
                img {
                    width: 90px;
                    height: 90px;
                    margin-block: 0;
                    margin-inline: auto;
                }

                h2 {
                    font-size: 28px;
                    line-height: 28px;
                    font-family: var(--poppin_stack);
                    font-weight: 600;
                    color: var(--parple);
                    margin-block-start: 20px;
                }
            }

            .login_foot {
                position: absolute;
                bottom: 0;
                width: 100%;

                .foot_login_body {
                    color: var(--join_foot_gray);
                    font-size: 12px;
                    font-family: var(--poppin_stack);
                    font-weight: 400;

                    a {
                        font-size: 12px;
                        font-family: var(--poppin_stack);
                        font-weight: 400;
                        color: var(--join_foot_gray);
                        text-decoration: underline !important;

                        &:hover {
                            color: var(--parple);
                        }
                    }
                }
            }

            .login_form {
                text-align: center;
                padding-block-start: 45px;

                .MuiFormControl-root {
                    width: 75%;
                }

                .MuiFormHelperText-root {
                    text-align: center;
                }

                .MuiInput-root:before {
                    border-bottom: 2px solid var(--border_line_color);
                }

                .MuiInput-root.Mui-focused:after {
                    border-bottom: 2px solid var(--border_prime);
                }

                .MuiInput-rootMui-error:after {
                    border-bottom: 2px solid var(--error_color);
                }

                input {
                    text-align: center;
                    font-family: var(--poppin_stack);
                    font-weight: 500;
                    font-size: 18px;
                    color: var(--input_color);
                    letter-spacing: 2px;
                    padding-block-end: 10px;
                }

                input::placeholder {
                    color: var(--placeholder_color);
                    opacity: 1;

                    &.Mui-error {
                        color: var(--error_color);
                    }
                }

                .login_btn {
                    margin-block-start: 36px;

                    button {
                        background: var(--btn_gradient);
                        color: var(--button_white_text);
                        width: 60px;
                        height: 60px;
                        box-shadow: none;
                        border-radius: 50%;
                        min-width: auto;
                        font-family: var(--poppin_stack);
                        font-size: 16px;
                        text-transform: none;

                        &.loading_btn {
                            .MuiLoadingButton-loadingIndicator {
                                left: 50%;
                                position: absolute;
                            }
                        }

                        &.MuiLoadingButton-root {
                            &.Mui-disabled {
                                opacity: 1;
                                cursor: default;
                            }
                        }

                        .MuiLoadingButton-startIconLoadingStart {
                            display: none;
                        }

                        .MuiCircularProgress-root {
                            width: 20px !important;
                            height: 20px !important;
                        }

                        .MuiLoadingButton-loadingIndicator {
                            position: relative;
                            left: 0;
                            display: flex;
                            padding: 0px;
                            color: var(--button_white_text);
                        }
                    }
                }
            }

            &.join_verifi {
                padding-block-start: 0;

                .sign_logo {
                    padding-block-start: 60px;

                    .blue {
                        width: 100px;
                        height: 100px;
                        margin-block: 0;
                        margin-inline: auto;
                        color: var(--parple);
                    }

                    .red {
                        width: 100px;
                        height: 100px;
                        margin-block: 0;
                        margin-inline: auto;
                        color: var(--error_color);
                    }

                    .MuiAvatar-root {
                        width: 98px;
                        height: 98px;
                        margin-block: 0;
                        margin-inline: auto;
                        border: var(--lightbg) solid 1px;

                        &.transparentImage {
                            background: var(--background_black);
                        }

                        // background: aquamarine;
                        img {
                            width: 98px;
                            height: 98px;
                        }
                    }

                    p {
                        font-size: 14px;
                        line-height: 20px;
                        font-family: var(--poppin_stack);
                        font-weight: 400;
                        color: var(--para_color);
                        margin-block-start: 16px;
                        padding-block: 0;
                        padding-inline: 12%;
                    }

                    h3 {
                        font-size: 20px;
                        line-height: 20px;
                        font-family: var(--poppin_stack);
                        font-weight: 600;
                        color: var(--parple);
                        margin-block-start: 26px;

                        &.tred {
                            color: var(--error_color);
                        }

                        &.torange {
                            color: var(--guest_not_allowed);
                        }
                    }
                }

                .login_form {
                    padding-block-start: 20px;
                    height: 87px;
                }

                .selectAvater {
                    padding-block: 15px 0;
                    padding-inline: 30px;

                    .MuiGrid-grid-xs-2 {
                        -webkit-flex-basis: 20%;
                        -ms-flex-preferred-size: 20%;
                        flex-basis: 20%;

                        max-width: 20%;
                    }

                    .camera_pop {
                        width: 48px;
                        height: 48px;
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        svg {
                            display: flex;
                            color: var(--parple);
                            position: relative;
                            top: -2px;
                        }

                        &:hover {
                            background: var(--parple);

                            svg {
                                color: var(--textWhite);
                            }
                        }

                        label {
                            line-height: 0vh;
                            cursor: pointer;
                            height: 20px;
                        }

                        input {
                            display: none;
                        }
                    }

                    .MuiAvatar-root {
                        width: 48px;
                        height: 48px;
                        cursor: pointer;
                        border: 1px solid transparent;

                        &.transparentImage {
                            background: var(--background_black);
                        }

                        img {
                            width: 48px;
                            // height: 48px;
                        }

                        &:hover {
                            border: var(--border_prime) solid 1px;
                        }

                        &.active {
                            border: var(--border_prime) solid 1px;
                        }

                        &:active {
                            border: var(--border_prime) solid 1px;
                        }
                    }
                }

                .reDirecting {
                    width: 100%;
                    @include displayFlex(column, center, flex-start);
                    gap: 10px;
                    margin-block-start: 80px;

                    p {
                        font-size: 16px;
                        line-height: 25px;
                        font-family: var(--poppin_stack);
                        font-weight: 400;
                        color: var(--para_color);
                        padding-block: 0;
                        padding-inline: 4%;

                        & + div {
                            width: 40px;
                            height: 40px;
                            margin-inline: auto;
                        }
                    }
                }

                .loginbtn {
                    margin-block-start: 30px;

                    &.mt_100 {
                        margin-block-start: 95px;
                    }

                    button {
                        background: var(--btn_gradient);
                        color: var(--button_white_text);
                        width: 160px;
                        height: 48px;
                        border-radius: 50px;
                        box-shadow: none;
                        text-transform: initial;
                        min-height: auto;
                        min-width: auto;
                        font-family: var(--poppin_stack);
                        letter-spacing: 1.1px;
                        text-transform: none;

                        &.loading_btn {
                            .MuiLoadingButton-loadingIndicator {
                                left: 50%;
                                position: absolute;
                                color: var(--button_white_text);
                            }
                        }

                        &.MuiLoadingButton-root {
                            &.Mui-disabled {
                                opacity: 1;
                                cursor: default;
                            }
                        }

                        .MuiLoadingButton-startIconLoadingStart {
                            display: none;
                        }

                        .MuiCircularProgress-root {
                            width: 20px !important;
                            height: 20px !important;
                        }

                        .MuiLoadingButton-loadingIndicator {
                            position: relative;
                            left: 0;
                            display: flex;
                            padding: 0px;
                            color: var(--button_white_text);
                        }
                    }
                }

                .class_code {
                    width: 100%;
                    text-align: center;

                    span {
                        color: var(--join_foot_gray);
                        font-size: 14px;
                        font-weight: 400;
                        font-family: var(--poppin_stack);
                        // text-transform: capitalize;
                    }

                    h1 {
                        color: var(--para_color);
                        font-size: 20px;
                        font-weight: 500;
                        font-family: var(--poppin_stack);
                    }
                }
            }

            .profile_upload {
                width: auto;
                position: relative;

                .upload_profile_images {
                    height: 136px;
                    width: 136px;
                    background: var(--light-bg);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border-radius: 50%;
                    margin-inline: auto;
                    position: relative;

                    input {
                        display: none;
                    }

                    label {
                        line-height: 0vh;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        gap: 4px;
                        width: 100%;
                        height: 100%;
                        justify-content: center;

                        .MuiAvatar-root {
                            width: 136px;
                            height: 136px;
                            margin-block: 0;
                            margin-inline: auto;
                            border: var(--lightbg) solid 1px;

                            &.transparentImage {
                                background: var(--background_black);
                            }

                            // background: aquamarine;
                            img {
                                width: 136px;
                                height: 136px;
                            }
                        }
                    }

                    span {
                        color: var(--parple);
                        font-size: 14px;
                        line-height: 24px;
                        font-family: var(--font-stack);
                        font-weight: 500;
                    }

                    .camerabtn {
                        background: var(--box_btn_hover);
                        border-radius: 50%;

                        svg {
                            font-size: 42px;
                            display: flex;
                        }

                        &:hover {
                            background: var(--Ripplebg_color);
                        }
                    }

                    .MuiSvgIcon-root {
                        font-size: 30px;
                        color: var(--backgroundcolor);
                    }
                }

                .changes_profile_images {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    z-index: 2;
                    width: 52px;
                    height: 52px;
                    border-radius: 50%;
                    font-size: 20px;
                    box-shadow: 0px 0.857143px 1.71429px rgba(0, 0, 0, 0.05),
                        0px 0.857143px 3.42857px 0.857143px rgba(0, 0, 0, 0.05);
                    background: linear-gradient(0deg, var(--color_ecf0f4), var(--color_ecf0f4)), var(--background_white);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &:hover {
                        background: linear-gradient(0deg, var(--light_dadfff), var(--light_dadfff)),
                            var(--background_white);
                    }

                    input {
                        display: none;
                    }

                    label {
                        line-height: 0vh;
                        cursor: pointer;
                        display: flex;
                    }

                    .MuiSvgIcon-root {
                        font-size: 24px;
                        color: var(--backgroundcolor);
                    }
                }
            }
        }
    }
}

.camera_modal {
    &.MuiModal-root .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.85);
    }

    .MuiBox-root {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 95%;
        border: none !important;
        outline: none !important;
        background: none;
        border-radius: 12px;
        padding: 0;
        width: fit-content;
    }

    .close_modal {
        position: absolute;
        top: 15px;
        right: 36px;
        z-index: 111;
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.8;
        color: var(--textColor);

        &:hover {
            opacity: 1;
        }
    }

    .camera_modal_inner {
        background: var(--background_white);
        position: relative;
        width: 95%;
        margin-block: 0;
        margin-inline: auto;
        width: 400px;
        border-radius: 12px;
        padding-block: 20px 0;
        padding-inline: 32px;

        .close_modal {
            right: 20px;
            color: var(--textColor);
        }

        .short_ans_head {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 1rem;

            img {
                filter: var(--filter_invert);
            }

            svg {
                color: var(--textblack);
            }

            h4 {
                font-family: var(--poppin_stack);
                color: var(--textblack);
                font-size: 16px;
                font-weight: 500;
            }
        }

        .croping_box {
            background: var(--background_black);
            width: 100%;
            border-radius: 12px;
            height: 279px;
            margin-block-start: 22px;

            .cropper-container {
                border-radius: 12px;

                .cropper-wrap-box {
                    border-radius: 12px;
                }

                .cropper-view-box {
                    outline: 1px solid var(--textWhite);
                    overflow: hidden;
                }

                .cropper-drag-box {
                    border-radius: 12px;
                    opacity: 0.8;
                }
            }
        }
    }

    .btn_camera {
        text-align: center;
        padding-block: 20px;
        // padding-inline: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        button {
            background: var(--btn_gradient);
            color: var(--button_white_text);
            width: 100%;
            max-width: 115px;
            height: 52px;
            border-radius: 50px;
            font-size: 16px;
            box-shadow: none;
            text-transform: none;
            font-family: var(--poppin_stack);
            text-transform: none;

            &.default {
                background: none;
                color: var(--parple);

                &:hover {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)),
                        var(--background_white);
                }
            }

            &.reset {
                background: none;
                color: var(--Activityerroe_color);

                &:hover {
                    background: linear-gradient(0deg, rgba(211, 47, 47, 0.2), rgba(211, 47, 47, 0.2)), #ffffff;
                }
            }

            &.MuiLoadingButton-root {
                &.Mui-disabled {
                    opacity: 1;
                    cursor: default;
                }
            }

            .MuiLoadingButton-startIconLoadingStart {
                display: none;
            }

            .MuiLoadingButton-loadingIndicator {
                position: relative;
                left: 0;
                display: flex;
                padding-inline: 0 5px;

                .MuiCircularProgress-root {
                    width: 20px !important;
                    height: 20px !important;
                }
            }
        }
    }
}

.language_change_btn {
    position: absolute;
    top: 40px;
    right: 40px;
    display: flex;
    align-items: center;
    background: var(--background_white);
    padding: 14px;
    gap: 10px;
    border-radius: 12px;
    cursor: pointer;

    img {
        width: 20px;
    }

    svg {
        display: flex;
        font-size: 20px;
        color: var(--parple);
    }

    h3 {
        font-family: var(--poppin_stack);
        color: var(--textColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
    }
}

@media (max-width: 600px) {
    .classpooint_joining .join_card {
        height: 504px;
    }

    .classpooint_joining .join_card .join_card_wrap {
        padding-block-start: 44px;
    }

    .classpooint_joining .join_card .join_card_wrap.join_verifi .sign_logo {
        padding-block-start: 20px;
    }

    .classpooint_joining .join_card .join_card_wrap.join_verifi .selectAvater {
        padding-top: 0;
    }

    .classpooint_joining .join_card .join_card_wrap.join_verifi .selectAvater .MuiAvatar-root {
        width: 40px;
        height: 40px;
    }

    .classpooint_joining .join_card .join_card_wrap.join_verifi .selectAvater .camera_pop {
        width: 40px;
        height: 40px;
    }

    .classpooint_joining .join_card .join_card_wrap.join_verifi .selectAvater .camera_pop svg {
        font-size: 18px;
    }

    .classpooint_joining .join_card .join_card_wrap.join_verifi .selectAvater .MuiAvatar-root img {
        width: 40px;
        height: 40px;
    }

    .classpooint_joining .join_card .join_card_wrap.join_verifi .loginbtn {
        margin-block-start: 20px;
    }

    .classpooint_joining .join_card .join_card_wrap.join_verifi .loginbtn.mt_100 {
        margin-block-start: 72px;
    }

    .classpooint_joining .join_card .join_card_wrap.join_verifi .selectAvater .camera_pop label {
        height: 14px;
    }

    .language_change_btn {
        top: 32px;
        right: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;

        h3 {
            display: none;
        }
    }
}

@media (max-width: 500px) {
    .classpooint_joining .join_card {
        width: 100%;
        max-width: 370px;
    }

    .camera_modal .camera_modal_inner {
        width: 320px;
        padding: 20px;
    }
}

@media (max-width: 530px) {
    .classpooint_joining .join_card {
        width: 100%;
        max-width: 350px;
    }

    .camera_modal .camera_modal_inner {
        width: 350px;
    }
}

@media (max-width: 420px) {
    .classpooint_joining .join_card {
        width: 95%;
        // max-width: 320px;
    }

    .camera_modal {
        .camera_modal_box {
            width: 95%;
            max-width: 350px;
            padding: 0 !important;

            .camera_modal_inner {
                width: 100%;
            }
        }
    }

    .classpooint_joining .join_card .join_card_wrap.join_verifi .selectAvater {
        padding-block: 0;
        padding-inline: 15px;
    }
}

@media (max-width: 320px) {
    .classpooint_joining .join_card .join_card_wrap .login_form .MuiFormControl-root {
        width: 100%;
    }

    .classpooint_joining .join_card .join_card_wrap .sign_logo img {
        width: 75px;
        height: 75px;
    }

    .classpooint_joining .join_card .join_card_wrap .sign_logo h2 {
        font-size: 26px;
        line-height: 26px;
    }

    .classpooint_joining .join_card {
        height: 480px;
    }

    .camera_modal .camera_modal_inner {
        width: 265px;
    }
}

.cropper-point.point-se {
    height: 5px !important;
    width: 5px !important;
}
