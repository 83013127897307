@use '../../../../../../../Style.scss' as *;

.asked_Question {
    width: 100%;
    padding-block-start: 24px;
    padding-inline: 32px;

    .question_profile {
        width: 100%;
        @include displayFlex(row, flex-start, flex-start);
        gap: 12px;
        border: 2px solid var(--border_gray);
        padding: 20px;
        border-radius: 12px;

        :global(.MuiAvatar-root) {
            height: 36px;
            width: 36px;
            font-size: 18px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .textarea {
            width: 100%;
            min-height: 100px;
            border: none;
            padding-block: 5px;
            font-size: 15px;
            resize: none;
            font-family: var(--poppin_stack);
            background: var(--background_white);
            color: var(--para_color);

            &::placeholder {
                color: var(--placeholder_color);
            }
        }
    }

    .btn_group {
        width: 100%;
        @include displayFlex(row, center, space-between);
        gap: 10px;
        margin-block: 18px;

        &.centerAlign {
            justify-content: center;
        }

        .backBtn {
            @include displayFlex(row, center, center);

            font-size: 14px;
            font-weight: var(--fw_medium);
            color: var(--btnbgcolor);
            text-transform: none;

            background: none;
            border-radius: 100px;

            .icon {
                font-size: 20px;
                margin-inline-end: 4px;
            }
        }

        .prime_btn {
            @include prime;

            .icon {
                @include displayFlex(row, center, center);
                padding-inline-end: 5px;
            }

            :global(.MuiLoadingButton-loadingIndicator) {
                position: static;
                padding-inline-end: 5px;
            }
        }
    }
}
